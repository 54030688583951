import React from 'react';
import {
    Button, Box, Container, Typography, Divider, Dialog,
    DialogTitle, DialogContent, FormControl, InputLabel,
    Select, MenuItem, DialogActions,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Menu
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import { deleteAlarmTypes, getAlarmGroups, getAlarmPriorities, getAlarmTypes, getMessageSources, importAlarmFile, saveAlarmTypes } from '../libs/apiCalls';
import ConfirmDialog from '../components/ConfirmDialog';

function AlarmTypes() {
    const defaultPageSize = 20;

    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const [gridRows, setGridRows] = React.useState([]);
    const [gridSelection, setGridSelection] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [reload, setReload] = React.useState(false);

    const [sourceList, setSourceList] = React.useState([]);
    const [priorityList, setPriorityList] = React.useState([]);
    const [selectedPriority, setSelectedPriority] = React.useState({});
    const [selectedSource, setSelectedSource] = React.useState({});
    const [alarmGroups, setAlarmGroups] = React.useState([]);
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [anchor, setAnchor] = React.useState(null);
    const [gridColumns, setGridColumns] = React.useState([]);
    const importFile = React.useRef(null);
    const open = Boolean(anchor);

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'source', headerName: 'Source', flex: 0.1 },
        { field: 'alarmType', headerName: 'Alarm Type', flex: 0.5 },
        { field: 'priority', headerName: 'Priority', flex: 0.2 }
    ];

    const clearSelection = () => {
        setGridSelection([]);
        setSelectedRow({});
        setSelectedPriority({});
        setSelectedSource({});
        setSelectedGroups([]);

        importFile.current.value = null;
    };

    const handleNew = () => {
        const alarmType = {
            id: 0,
            sourceId: '',
            priorityId: '',
            alarmType: '',
            priority: '',
            alarmGroupIds: []
        };

        setSelectedRow(alarmType);

        setAnchor(null);
        setOpenDialog(true);
        setGridSelection([]);
    };

    const handleEdit = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDialog(true);
        }
    };

    const handleDelete = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDelete(true);
        }
    };

    const handleRowSelected = (ids) => {
        setGridSelection(ids);

        if (ids.length > 0) {
            var row = gridRows.find((row) => row.id === ids[0]);

            if (selectedRow.id !== row.id) {
                setSelectedRow(row);

                setSelectedPriority(priorityList.find((item) => item.id === row.priorityId));
                setSelectedSource(sourceList.find((item) => item.sourceId === row.sourceId));
                setSelectedGroups(row.alarmGroupIds ? row.alarmGroupIds.split(',') : []);
            }
        }
    };

    const handlePageModelChanged = (event) => {
        getData(event.page, event.pageSize);
    };

    const handleCellDoubleClick = (params, event) => {
        setOpenDialog(true);
    };

    const getData = (page, pageSize) => {
        getAlarmTypes(settings.token, page, pageSize, 'source', 0,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });

                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        )

        if (sourceList.length === 0) {
            getMessageSources(settings.token, 0, 100, 'Name', 0,
                (response) => {
                    if (!response.data.error) {
                        setSourceList(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }

        if (priorityList.length === 0) {
            getAlarmPriorities(settings.token,
                (response) => {
                    if (!response.data.error) {
                        setPriorityList(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }


    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setReload(!reload);

        clearSelection();
    };

    const handleSubmitDialog = (event) => {
        event.preventDefault();
        setOpenDialog(false);

        if (Object.keys(selectedRow).length > 0) {
            const alarmType = {
                id: selectedRow.id,
                sourceId: selectedSource.sourceId,
                priorityId: selectedPriority.id,
                alarmType: selectedRow.alarmType,
                priority: selectedPriority,
                alarmGroupIds: selectedGroups
            };

            if (selectedRow.id > 0) {
                alarmType.createdBy = selectedRow.createdBy;
                alarmType.createdDate = selectedRow.createdDate;
                
                alarmType.updatedBy = settings.userName;
                alarmType.updatedDate = new Date();
            }
            else {
                alarmType.createdBy = settings.userName;
                alarmType.createdDate = new Date();
                
                alarmType.updatedBy = null;
                alarmType.updatedDate = null;
            }

            saveAlarmTypes(settings.token, [alarmType],
                (response) => {
                    if (!response.data.error) {
                        clearSelection();
                        setSnackbar("Record added/updated successfully.");
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
    }

    const handleOnChange = (event) => {
        if (event.target.name === 'priorityId' && event.target.value > 0) {
            setSelectedPriority(priorityList.find((item) => item.id === event.target.value));
        }
        else if (event.target.name === 'sourceId') {
            setSelectedSource(sourceList.find((item) => item.sourceId === event.target.value));
        }

        selectedRow[event.target.name] = event.target.value;
    };

    const handleCancelDelete = () => {
        setOpenDelete(false);
        setReload(!reload);
    };

    const doDelete = () => {
        setOpenDelete(false);

        if (Object.keys(selectedRow).length > 0) {
            deleteAlarmTypes(settings.token, [selectedRow.id],
                (response) => {
                    if (!response.data.error) {
                        clearSelection();
                        setSnackbar("Alarm Type deleted successfully.");
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            );
        }
    };

    const handleAddClick = (event) => {
        setAnchor(event.currentTarget);
    }

    const handleMenuClose = (event) => {
        setAnchor(null);
    };

    const handleImportFile = (event) => {
        setAnchor(null);

        importFile.current.click();
    };

    const handleFileChange = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);

        importAlarmFile(settings.token, formData,
            (response) => {
                if (!response.data.error) {
                    setDialog(JSON.parse(response.data.result).join('\n'));
                    clearSelection();
                    setReload(!reload);
                }
                else {
                    setDialog(response.data.errors[0], true);
                }                
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handleGroupChange = (event) => {
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, event.target.value]);
        }
        else {
            setSelectedGroups(selectedGroups.filter((id) => id !== event.target.value));
        }
    };

    React.useEffect(() => {
        getAlarmGroups(settings.token, 
            (response) => {
                if (!response.data.error) {
                    setAlarmGroups(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );    

        setGridColumns(columns);
    }, []);

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData(0, defaultPageSize);
        }
    }, [settings, reload]);

    React.useEffect(() => {
        const typeCols = alarmGroups.map((item) => {
            return (
                {
                    field: item.name,
                    headerName: item.name,
                    flex: 0.1,
                    renderCell: (props) => {
                        return (
                            props.row.alarmGroupIds && props.row.alarmGroupIds.includes(item.id) ? <CheckIcon sx={{ ml: 1 }} /> : <CloseOutlinedIcon sx={{ ml: 1 }} />
                        )
                    }
                }
            );
        });

        setGridColumns(columns.concat(typeCols)); 
    }, [alarmGroups]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Types</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Define source system alarm types, priorities and destinations.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="outlined" color="error" 
                        style={{ width: "120px" }} 
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                    <Button variant="contained" color="success"
                        style={{ width: "120px", marginLeft: "7px" }}
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={handleAddClick}
                        disableElevation
                        aria-haspopup="true"
                    >
                        Add
                    </Button>
                    <Menu
                        open={open}
                        onClose={handleMenuClose}
                        elevation={0}
                        anchorEl={anchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            marginTop: 1
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    minWidth: 150,
                                    border: 1,
                                    borderColor: 'grey'
                                },
                            }
                        }}
                    >
                        <MenuItem onClick={handleNew} disableRipple sx={{ justifyContent: 'flex-end'}}>
                            Add new Alarm Type
                        </MenuItem>
                        <MenuItem onClick={handleImportFile} disableRipple sx={{ justifyContent: 'flex-end'}}>
                            Import from file
                        </MenuItem>
                    </Menu>
                </Box>
                <input 
                    type='file' 
                    id='import-file' 
                    ref={importFile} 
                    style={{display: 'none'}}
                    accept='.csv,.txt'
                    onChange={handleFileChange}
                />
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="xl">
                <Box sx={{ width: '100%' }}>
                    <CustomDataGrid
                        columns={gridColumns}
                        paginationMode='server'

                        rows={gridRows}
                        rowCount={serverData.totalRecords}
                        getRowId={(row) => row.id}
                        onCellDoubleClick={handleCellDoubleClick}

                        pagination
                        pageSizeOptions={[10, 20, 30, 50]}
                        onPaginationModelChange={handlePageModelChanged}
                        onRowSelectionModelChange={handleRowSelected}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        rowSelectionModel={gridSelection}
                    />
                </Box>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth={true}
                    PaperProps={{
                        component: "form",
                        onSubmit: (handleSubmitDialog)
                    }}
                >
                    <DialogTitle>Alarm Type</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex", gap: 2 }} spacing={2}>
                            <FormControl variant="standard" sx={{ mt: 1, flexGrow: 0.5 }}>
                                <InputLabel id="source-label" required>Message Source</InputLabel>
                                <Select
                                    labelId="source-label"
                                    id="source-select"
                                    name="sourceId"
                                    required
                                    sx={{ width: '100%' }}
                                    value={openDialog ? selectedRow.sourceId : ''}
                                    onChange={handleOnChange}
                                >
                                    {sourceList.map((item, index) => (
                                        <MenuItem key={index} value={item.sourceId}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ mt: 1, flexGrow: 0.5 }}>
                                <InputLabel id="source-label" required>Alarm Priority</InputLabel>
                                <Select
                                    labelId="priority-label"
                                    id="priority-select"
                                    name="priorityId"
                                    required
                                    sx={{ width: '100%' }}
                                    value={openDialog ? selectedRow.priorityId : ''}
                                    onChange={handleOnChange}
                                >
                                    {priorityList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>                            

                        </Box>
                        <Box sx={{ display: "flex", mt: 1, gap: 2 }} spacing={2}>
                            <TextField sx={{ flexGrow: 1 }}
                                margin="dense"
                                id="alarmType"
                                name="alarmType"
                                label="Alarm Type"
                                type="text"
                                required
                                defaultValue={openDialog ? selectedRow.alarmType : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
                            <Typography sx={{ fontWeight: 500 }} color="primary">Enable notifications for:</Typography>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                {alarmGroups.map((item, index) => (
                                    <FormControlLabel key={index}
                                        control={<Checkbox checked={selectedGroups.includes(item.id.toString())}/>}
                                        value={item.id}
                                        label={item.name}                                        
                                        sx={{ width: 160 }}
                                        onChange={handleGroupChange}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit" variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </Container>
            <ConfirmDialog open={openDelete} title={"Confirm Delete"}
                content={"Are you sure you want to remove this alarm type? (Note: this cannot be undone)"}
                onCancelClick={handleCancelDelete}
                onYesClick={doDelete}
                yesNo
            />
        </>
    )
}

export default AlarmTypes;
