 import React from 'react';

import {
    Box, Button, Card, CardActionArea, CardContent,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,
    LinearProgress,
    Tooltip, Typography
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import ApplicationContext from './contexts/application';
import { CustomDataGrid } from './components/CustomDataGrid';
import { getAlarmTotals, getFilteredAlarms } from './libs/apiCalls';
import dayjs from 'dayjs';
import AppLog from './admin/applog';
import CustomFilter from './components/CustomFilter';
import { useLocalStorage } from './libs/useLocalStorage';

function AlarmViewer() {
    const defaultPageSize = 20;
    const lastWeek = new Date().getDate() - 7;
    const defaultColVisibility = {
        inactiveDate: false
    };

    const { settings, setDialog, setBadgeCount, onMessage } = React.useContext(ApplicationContext);
    const [columnCache, setColumnCache] = useLocalStorage('alarmGrid', []);
    const [visibleCache, setVisibleCache] = useLocalStorage('alarmGrid.Visibility', defaultColVisibility);

    const [gridRows, setGridRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [selectedPriority, setSelectedPriority] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [openLogDialog, setOpenLogDialog] = React.useState(false);

    const [columVisibility, setColumnVisibility] = React.useState(Object.keys(visibleCache).length === 0 ? {
        inactiveDate: false
    } : { ...visibleCache, ...defaultColVisibility });

    const alarmTotals = React.useRef({
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 0,
        ACtive: 0,
        Total: 0
    });

    const filter = React.useRef({
        searchString: '',
        fromDate: dayjs(new Date().setDate(lastWeek)).format('YYYY-MM-DD'),
        toDate: '',
        priority: '',
        topic: '',
        status: 'Active',
        roles: settings.roles
    });

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    // caching the column widths does not save the formatter functions
    const fixCachedCols = (cache) => {
        const activeDate = columnCache.find(c => c.field === 'activeDate');
        if (activeDate) {
            activeDate.valueFormatter = (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '';
        }

        const inactiveDate = columnCache.find(c => c.field === 'inactiveDate');
        if (inactiveDate) {
            inactiveDate.valueFormatter = (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '';
        }

        return cache;
    };

    const columns = columnCache.length === 0 ? [
        {
            field: 'id', headerName: 'ID', width: 80,
        },
        {
            field: 'activeDate', headerName: 'Active Date', width: 200,
            valueFormatter: (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss')  
        },
        {
            field: 'inactiveDate', headerName: 'Inactive Date', width: 200,
            valueFormatter: (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        { field: 'priority', headerName: 'Priority', width: 150 },
        { field: 'location', headerName: 'Location', flex: 0.3 },
        { field: 'type', headerName: 'Alarm Type', flex: 0.3 },
        { field: 'message', headerName: 'Alarm Message', flex: 1 },
        { field: 'source', headerName: 'Source', width: 125 },
        { field: 'status', headerName: 'Status', width: 100 },
    ] : fixCachedCols(columnCache);

    const getTotals = (filter) => {
        getAlarmTotals(settings.token, JSON.stringify(filter),
            (response) => {
                if (!response.data.error) {
                    const totals = JSON.parse(response.data.result);
                    totals.forEach((value, key) => {
                        alarmTotals.current[value.Name] = value.Count;
                    });

                    const active = totals.find(item => item.Name === 'Active');
                    if (active) {
                        setBadgeCount(active['Count']);
                    }
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const getFilteredData = (filter, page, pageSize) => {
        setLoading(true);
        filter.roles = settings.roles;

        getTotals(filter);

        getFilteredAlarms(settings.token, JSON.stringify(filter), page, pageSize, 'activeDate', 1,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });

                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        );
    };

    const handlePageModelChanged = (event) => {
        getFilteredData(filter.current, event.page, event.pageSize);
    };

    const handleClearFilter = () => {
        setSelectedPriority('');
        setColumnVisibility({ ...visibleCache, ...defaultColVisibility });

        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handlePriorityClick = (priority) => {
        if (selectedPriority === priority) {
            setSelectedPriority('');
            setColumnVisibility({ ...visibleCache, ...defaultColVisibility });

            filter.current.status = 'Active';
            filter.current.priority = '';
        }
        else {
            setSelectedPriority(priority);

            filter.current.status = priority === 'Total' ? '' : 'Active';
            filter.current.priority = priority === 'Total' ? '' : priority;

            if (priority === 'Total') {
                setColumnVisibility({ ...visibleCache, ...{ inactiveDate: true }});
            }
        }

        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handleOpacity = (id) => {
        return selectedPriority === '' || selectedPriority === id ? 1 : 0.2;
    };

    const handleFilter = () => {
        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handleRowSelected = (ids) => {
        if (ids.length > 0) {
            setSelectedRow(gridRows.find((row) => row.id === ids[0]));
        }
    };    
    
    const handleCellDoubleClick = (params, event) => {
        setOpenLogDialog(true);
    };

    const handleColumnWidthChange = (params, event, details) => {
        const col = columns.find(c => c.headerName === params.colDef.headerName);
        if (col) {
            delete col.flex;

            col.width = params.width;
            setColumnCache(columns);
        }
    };

    const handleColumnVisibilityChange = (model) => {
        setColumnVisibility(model);
        setVisibleCache(model);
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && gridRows.length === 0) {
            getFilteredData(filter.current, 0, defaultPageSize);
        }
    }, [settings]);

    React.useEffect(() => {
        if (Object.keys(onMessage).length > 0) {
            getFilteredData(filter.current, 0, defaultPageSize);
        }
    }, [onMessage]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Viewer</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Review active and historical alarms. By default, for the last 7 days.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth='lg' sx={{ display: 'flex', gap: 2 }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, maxWidth: 215, backgroundColor: '#cf2d2e', opacity: () => handleOpacity('Critical') }} >
                        <Tooltip title={selectedPriority === 'Critical' ? 'Clear filter' : 'Show active critical alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Critical')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Critical</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Critical.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#ff9a03', opacity: () => handleOpacity('High') }} >
                        <Tooltip title={selectedPriority === 'High' ? 'Clear filter' : 'Show active high alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('High')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>High</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.High.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#1aa1fb', opacity: () => handleOpacity('Medium') }} >
                        <Tooltip title={selectedPriority === 'Medium' ? 'Clear filter' : 'Show active medium alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Medium')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Medium</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Medium.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#bc93f0', opacity: () => handleOpacity('Low') }} >
                        <Tooltip title={selectedPriority === 'Low' ? 'Clear filter' : 'Show active low priority alarms'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Low')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Low</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Low.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 0.5 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: 'darkgray', opacity: () => handleOpacity('Total'), maxWidth: 215 }}>
                        <Tooltip title={selectedPriority === 'Total' ? 'Clear filter' : 'Show all alarms (inc. inactive)'} placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Total')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '80%' }}>{ selectedPriority === 'Total' ? 'Showing all alarms' : 'All (Active/Inactive)' }</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '20%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Total.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
            </Container>

            <Grid container gap={2} sx={{ mt: 2 }}>
                <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                    <CustomFilter filter={filter}
                        onFilter={handleFilter}
                        onClearFilter={handleClearFilter}
                    />
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: 'column',  width: '100%' }}>
                    <LinearProgress sx={{ width: '100%', display: loading ? 'block' : 'none' }} />
                    <CustomDataGrid
                        columns={columns}
                        paginationMode='server'

                        rows={gridRows}
                        rowCount={serverData.totalRecords}
                        getRowId={(row) => row.id}

                        pagination
                        pageSizeOptions={[10, 20, 30, 50]}
                        onPaginationModelChange={handlePageModelChanged}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterExcludeHiddenColumns: true
                                }
                            }
                        }}

                        getRowClassName={(params) => {
                            const active = params.row.status === 'Active';
                            if (active) {                            
                                return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : '';
                            }
                            else {
                                return 'inactive';
                            }
                        }}

                        columnVisibilityModel={columVisibility}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onRowSelectionModelChange={handleRowSelected}
                        onCellDoubleClick={handleCellDoubleClick}

                        autoHeight
                    />
                </Grid>
                <Dialog
                    open={openLogDialog}
                    maxWidth='lg'
                    fullWidth={true}
                    PaperProps={{
                        sx: {
                            height: '50vh',
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                            setOpenLogDialog(false);
                        }
                    }}
                >
                    <DialogTitle>Message Log</DialogTitle>
                    <DialogContent>
                        <AppLog refIds={[selectedRow.activeRefId, selectedRow.inactiveRefId]} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenLogDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    );
}

export default AlarmViewer;