

import React from 'react';
import {
    Button, Box, Container, Typography, Divider, Dialog, DialogTitle,
    DialogActions, DialogContent, TextField, FormGroup, FormControlLabel,
    Checkbox,
    LinearProgress
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import { deleteAlarmPriority, getAlarmPriorities, saveAlarmPriority } from '../libs/apiCalls';
import ConfirmDialog from '../components/ConfirmDialog';


function AlarmPriorities() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);

    const [gridRows, setGridRows] = React.useState([]);
    const [gridSelection, setGridSelection] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Name', flex: 0.2 },
        { field: 'description', headerName: 'Description', flex: 0.5 },
        {
            field: 'toAlerts', headerName: 'Alerts', flex: 0.1,
            renderCell: (props) => {
                return (
                    props.value ? <CheckIcon sx={{ ml: 1 }} /> : <CloseOutlinedIcon sx={{ ml: 1 }} />
                )
            }
        },
        {
            field: 'toEmails', headerName: 'Emails', flex: 0.1,
            renderCell: (props) => {
                return (
                    props.value ? <CheckIcon sx={{ ml: 1 }} /> : <CloseOutlinedIcon sx={{ ml: 1 }} />
                )
            }
        },
        {
            field: 'toViewer', headerName: 'Viewer', flex: 0.1,
            renderCell: (props) => {
                return (
                    props.value ? <CheckIcon sx={{ ml: 1 }} /> : <CloseOutlinedIcon sx={{ ml: 1 }} />
                )
            }
        }
    ];

    const newRecord = {
        name: '',
        description: '',
        toAlerts: false,
        toEmails: false,
        toViewer: false,
        createdBy: settings.userName
    };

    const clearSelection = () => {
        setGridSelection([]);
        setSelectedRow({});
    };

    const handleNew = () => {
        setSelectedRow(newRecord);
        setOpenDialog(true);
    };

    const handleEdit = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDialog(true);
        }
    };

    const handleDelete = () => {
        if (Object.keys(selectedRow).length > 0) {
            setOpenDelete(true);
        }
    };

    const handleRowSelected = (ids) => {
        setGridSelection(ids);

        if (ids.length > 0) {
            setSelectedRow(gridRows.find((row) => row.id === ids[0]));
        }
    };

    const handleCellDoubleClick = (params, event) => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setReload(!reload);

        clearSelection();
    };

    const handleSubmitDialog = (event) => {
        event.preventDefault();
        setOpenDialog(false);
        setLoading(true);

        if (Object.keys(selectedRow).length > 0) {
            saveAlarmPriority(settings.token, selectedRow,
                (response) => {
                    if (!response.data.error) {
                        setSnackbar("Record added/updated successfully.");
                        clearSelection();
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }

                    setLoading(false);
                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                }
            );
        }
    }

    const handleOnChange = (event) => {
        if (Object.keys(selectedRow).length > 0) {
            if (event.target.type === 'checkbox') {
                selectedRow[event.target.name] = event.target.checked;
            }
            else {
                selectedRow[event.target.name] = event.target.value;
            }
        }
    };

    const doDelete = () => {
        setOpenDelete(false);
        setLoading(true);

        if (Object.keys(selectedRow).length > 0) {
            deleteAlarmPriority(settings.token, selectedRow,
                (response) => {
                    if (!response.data.error) {
                        clearSelection();
                        setSnackbar("Alarm Priority deleted successfully.");
                        setReload(!reload);
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                
                    setLoading(false);
                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                }
            );
        }
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            setLoading(true);

            getAlarmPriorities(settings.token,
                (response) => {
                    if (!response.data.error) {
                        setGridRows(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true)
                    }

                    setLoading(false);
                },
                (error) => {
                    setDialog(error.message, true);
                    setLoading(false);
                }
            )
        }
    }, [settings, reload]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Priorities</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Define priorities and forwarding destinations.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleDelete}>Delete</Button>
                    <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                    <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} onClick={handleNew}>Add</Button>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth="xl">
                <LinearProgress sx={{ display: loading ? 'display' : 'none' }} />
                <Box sx={{ width: '100%' }}>
                    <CustomDataGrid
                        columns={columns}
                        rows={gridRows}
                        getRowId={(row) => row.id}
                        onCellDoubleClick={handleCellDoubleClick}

                        pagination
                        pageSizeOptions={[10, 20]}
                        onRowSelectionModelChange={handleRowSelected}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }

                        rowSelectionModel={gridSelection}
                        autoHeight
                    />
                </Box>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth={true}

                    PaperProps={{
                        component: "form",
                        onSubmit: (handleSubmitDialog)
                    }}
                >
                    <DialogTitle>Alarm Priority</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex" }} spacing={2}>
                            <TextField sx={{ mr: 2, width: 200 }}
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                defaultValue={openDialog ? selectedRow.name : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                            <TextField sx={{ flexGrow: 1 }}
                                margin="dense"
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                defaultValue={openDialog ? selectedRow.description : ''}
                                onChange={handleOnChange}
                                variant="standard"
                            />
                        </Box>
                        <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                            <Typography sx={{ fontWeight: 500 }} color="primary">Send notifications to:</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 2, mr: 2 }}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={openDialog ? selectedRow.toAlerts : false}
                                        key={Math.random()}
                                        name='toAlerts'
                                        sx={{}}
                                        onChange={handleOnChange} />
                                }
                                    label="Alerts & Event System"
                                />
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={openDialog ? selectedRow.toEmails : false}
                                        key={Math.random()}
                                        name='toEmails'
                                        onChange={handleOnChange} />
                                }
                                    label="Email Distribution"
                                />
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={openDialog ? selectedRow.toViewer : false}
                                        key={Math.random()}
                                        name='toViewer'
                                        onChange={handleOnChange} />
                                }
                                    label="Alarm Viewer Log"
                                />
                            </FormGroup>
                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, flexGrow: 1 }} >
                                <TextField sx={{ flexGrow: 1 }}
                                    id='alertTopic'
                                    name='alertTopic'
                                    type='text'
                                    defaultValue={openDialog ? selectedRow.alertTopic : ''}
                                    variant='standard'
                                    size='small'
                                    onChange={handleOnChange}
                                />
                                <TextField sx={{ flexGrow: 1 }}
                                    id='emailTopic'
                                    name='emailTopic'
                                    type='text'
                                    defaultValue={openDialog ? selectedRow.emailTopic : ''}
                                    variant='standard'
                                    size='small'
                                    onChange={handleOnChange}
                                />
                                <TextField sx={{ flexGrow: 1 }}
                                    id='viewerTopic'
                                    name='viewerTopic'
                                    type='text'
                                    defaultValue={openDialog ? selectedRow.viewerTopic : ''}
                                    variant='standard'
                                    size='small'
                                    onChange={handleOnChange}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit" variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </Container>

            <ConfirmDialog open={openDelete} title={"Confirm Delete"}
                content={"Are you sure you want to remove this priority? (Note: this cannot be undone)"}
                onCancelClick={() => setOpenDelete(false)}
                onYesClick={doDelete}
                yesNo
            />
        </>
    )
}

export default AlarmPriorities;