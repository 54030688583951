import React from "react";
import { useNavigate } from "react-router-dom";

import ApplicationContext from "./contexts/application";

function UserProtected({ children }) {
    const { settings } = React.useContext(ApplicationContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            if (settings.token === "") {
                navigate("/");
            }
        }
    });

    return (
        children
    );
};

export default UserProtected;
