import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from 'prop-types';


ErrorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onOkClick: PropTypes.func.isRequired
}

function ErrorDialog(props) {

    return (
        <Dialog
            open={props.open}
            maxWidth="xs"
            fullWidth={true}
            keepMounted
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                {props.content} 
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onOkClick} variant="contained" color="error">OK</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ErrorDialog;