import { Link } from "react-router-dom"

function ErrorPage() {
    
    return (
        <div>
            <h1>Ooops!</h1>
            <h3>Page not found!</h3>

            <Link to="/">Go back</Link>
        </div>
    )
};

export default ErrorPage;