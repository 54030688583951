
import { useContext, useEffect } from 'react';
import ApplicationContext from '../contexts/application';

function Alarms() {
    const { settings } = useContext(ApplicationContext);

    return (
        <>
            <div><p>Alarms</p></div>
        </>
    )
}

export default Alarms;