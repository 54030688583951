import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import ApplicationContext from '../../contexts/application';
import { getTopicTotals } from '../../libs/apiCalls';


export default function Summary() {
    // only show up to 6 in this widget
    const topics = [
        { label: "", total: 0 },
        { label: "", total: 0 },
        { label: "", total: 0 },
        { label: "", total: 0 },
        { label: "", total: 0 },
        { label: "", total: 0 },
    ];

    const { settings, setDialog } = React.useContext(ApplicationContext);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);

    const [msgTotal, setMsgTotal] = React.useState(0);    
    const [totals, setTotals] = React.useState(topics);

    const getData = () => {
        setLoading(true);

        getTopicTotals(settings.token,
            (response) => {
                if (!response.data.error) {
                    const result = JSON.parse(response.data.result);
                    result.forEach((item, index) => {
                        topics[index].label = item.label;
                        topics[index].total = item.total;
                    });

                    setData(result);
                    setTotals(topics);
                    setMsgTotal(result.reduce((t, c) => t + c.total, 0));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        );
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData();
        }
    }, [settings]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Summary (All time)</Typography>
            {loading || data.length === 0 ?
                <CircularProgress color='success' /> :
                <>
                    <Typography component="p" >
                        <strong>Message Total:</strong> {msgTotal.toLocaleString()}
                    </Typography>

                    <Grid container sx={{ display: 'flex', ml: 2, mt: 3 }}>
                        <Grid container sx={{ flexDirection: 'row', mb: 1 }}>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[0].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[0].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                    <strong>{totals[0].label}</strong>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[1].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[1].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <strong>{totals[1].label}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ flexDirection: 'row', mb: 1 }}>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[2].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[2].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <strong>{totals[2].label}</strong>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[3].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[3].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <strong>{totals[3].label}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ flexDirection: 'row', mb: 1 }}>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[4].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[4].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <strong>{totals[4].label}</strong>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ flexGrow: 0.5 }} visibility={totals[5].label.length > 0 ? 'visible' : 'hidden'}>
                                <Typography component="p">
                                    {totals[5].total.toLocaleString()}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <strong>{totals[5].label}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

        </React.Fragment>
    );
}