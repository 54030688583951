
import React from "react";

import { IconButton, InputAdornment, MenuItem, 
    TextField, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
    
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';

function CustomFilter({ filter, onFilter, onClearFilter }) {
    const lastWeek = new Date().getDate() - 7;

    const [searchString, setSearchString] = React.useState('');
    const [priorityFilter, setPriorityFilter] = React.useState('');

    const handleFilterChange = (event) => {
        filter.current[event.target.name] = event.target.value;

        if (event.target.name === 'searchString') {
            setSearchString(event.target.value);
        }

        setPriorityFilter(filter.current.priority);
    }

    const handleFilterDateChange = (target, date) => {
        filter.current[target] = date ? dayjs(date.$d).format('YYYY-MM-DD') : '';
        handleFilter();
    };

    const handleFilter = () => {
        onFilter(filter);
    };

    const handleClearFilter = () => {
        filter.current.searchString = '';
        filter.current.fromDate = dayjs(new Date().setDate(lastWeek)).format('YYYY-MM-DD');
        filter.current.toDate = '';
        filter.current.priority = '';
        filter.current.status = 'Active';

        setPriorityFilter('');
        setSearchString('');

        onClearFilter();
    };

    return (
        <>
            <TextField
                variant='outlined'
                name='searchString'
                size='small'
                placeholder='Search for any attribute or value'
                InputProps={{
                    startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                }}
                value={searchString}
                onChange={handleFilterChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleFilter();
                    }
                }}
                sx={{ width: '40%' }} />

            <DatePicker
                name='fromDate'
                slotProps={{
                    textField: {
                        size: 'small',
                        placeholder: 'From Date'
                    }
                }}
                sx={{
                    width: '15%'
                }}
                onChange={(date) => handleFilterDateChange('fromDate', date)}
                value={filter.current.fromDate ? dayjs(filter.current.fromDate) : null}
            />

            <DatePicker
                name='toDate'
                slotProps={{
                    textField: {
                        size: 'small',
                        placeholder: 'To Date'
                    }
                }}
                sx={{
                    width: '15%'
                }}
                onChange={(date) => handleFilterDateChange('toDate', date)}
                value={filter.current.toDate ? dayjs(filter.current.toDate) : null}
            />

            <TextField
                name='priority'
                size='small'
                select
                sx={{
                    width: '20%',
                    '& .MuiSelect-select span::before': {
                        content: "'Priority'",
                    },
                }}
                onChange={handleFilterChange}
                value={priorityFilter}
            >
                <MenuItem value=''><em>All</em></MenuItem>
                <MenuItem value='Critical'>Critical</MenuItem>
                <MenuItem value='High'>High</MenuItem>
                <MenuItem value='Medium'>Medium</MenuItem>
                <MenuItem value='Low'>Low</MenuItem>
            </TextField>

            <Tooltip title="Apply filter">
                <IconButton
                    onClick={handleFilter}
                    color='primary'
                    sx={{ mt: -1, ml: -1 }}
                >
                    <FilterAltIcon fontSize="large" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Clear filters">
                <IconButton
                    onClick={handleClearFilter}
                    color='primary'
                    sx={{ mt: -1, ml: -1 }}
                >
                    <FilterAltOffIcon fontSize="large" />
                </IconButton>
            </Tooltip>

        </>
    );
}

export default CustomFilter;