
import React from "react";
import { useLocation, useNavigate } from "react-router";
import {
    Avatar, Box, Button, Checkbox, Container,
    Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment,
    Paper, TextField, Tooltip, Typography
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import ApplicationContext from '../contexts/application';
import { getAlarmGroups, saveUser } from "../libs/apiCalls";

function UserProfile() {
    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const { state: user } = useLocation();
    const navigate = useNavigate();

    const [username, setUsername] = React.useState(user.userName);
    const [showPassword, setShowPassword] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [alarmGroups, setAlarmGroups] = React.useState([]);
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(user.administrator);

    const handleOnChange = (event) => {
        user[event.target.name] = event.target.value;
        if (event.target.name === 'userName') {
            setUsername(event.target.value);
        }
        else if (event.target.name === 'tokenExpiry') {
            user.tokenExpiry = user.tokenExpiry * 60 * 1000;
        }

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    const handleAdminChange = (event) => {
        user[event.target.name] = event.target.checked;
        setIsAdmin(event.target.checked);

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    const handleCancel = () => {
        setSelectedGroups([]);
        navigate("/admin/users");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsDirty(false);

        user.alarmGroupIds = selectedGroups;

        saveUser(settings.token, user,
            (response) => {
                if (response.data.error) {
                    setDialog(response.data.errors[0], true);
                }
                else {
                    setSnackbar('Record added/updated successfully.');
                    navigate('/admin/users');
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        setSelectedGroups([]);
    };

    const handleGroupChange = (event) => {
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, event.target.value]);
        }
        else {
            setSelectedGroups(selectedGroups.filter((id) => id !== event.target.value));
        }

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    React.useEffect(() => {
        setSelectedGroups(user.alarmGroupIds ? user.alarmGroupIds.split(',') : []);

        getAlarmGroups(settings.token, 
            (response) => {
                if (!response.data.error) {
                    setAlarmGroups(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );    
    }, []);

    React.useEffect(() => {
        const unloadCallback = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [isDirty]);

    return (
        <>
            <form onSubmit={handleSubmit} >
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ width: "50%" }}>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '400', fontSize: '25px' }}>User Profiles</Typography>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '300', fontSize: '15px' }}>Users / Profile</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                        <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} type='submit'>Save</Button>
                    </Box>
                </Box>
                <Divider sx={{ mb: 4 }} />
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}  >
                            <Paper spacing={2}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '300px',
                                    boxShadow: 1
                                }}>
                                <Avatar sx={{ width: 100, height: 100, mt: 4 }} />
                                <Typography sx={{ m: 2 }}>{username}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={9} >
                            <Paper spacing={2}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 4,
                                    minHeight: '300px'
                                }}
                            >
                                <Typography sx={{ m: 2, fontWeight: 500 }} color="primary">Profile</Typography>
                                <Divider sx={{ mb: 2 }} />
                                <Grid item sx={{ display: 'flex', flexDirection: 'row', mx: 2, mb: 2, gap: 2 }} >
                                    <TextField
                                        required
                                        variant='standard'
                                        id='username'
                                        name='userName'
                                        label='User name'
                                        type='text'
                                        defaultValue={user.userName}
                                        onChange={handleOnChange}
                                        sx={{ width: '50%' }}
                                    />
                                    <TextField
                                        required
                                        variant='standard'
                                        id='password'
                                        name='password'
                                        label='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        defaultValue={user.password}
                                        onChange={handleOnChange}
                                        sx={{ width: '50%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ display: 'flex', flexDirection: 'row', mx: 2, mb: 2, gap: 2 }} >
                                    <TextField
                                        variant='standard'
                                        id='firstname'
                                        name='firstName'
                                        label='First Name'
                                        type='text'
                                        defaultValue={user.firstName}
                                        onChange={handleOnChange}
                                        sx={{ width: '50%' }}
                                    />
                                    <TextField
                                        variant='standard'
                                        id='lastname'
                                        name='lastName'
                                        label='Last Name'
                                        type='text'
                                        defaultValue={user.lastName}
                                        onChange={handleOnChange}
                                        sx={{ width: '50%' }}
                                    />
                                </Grid>
                                <Grid item sx={{ display: 'flex', flexDirection: 'row', mx: 2, mb: 2, gap: 2 }} >
                                    <TextField
                                        variant='standard'
                                        id='expiry'
                                        name='tokenExpiry'
                                        label='Login Expiry'
                                        type='text'
                                        defaultValue={user.tokenExpiry / 60 / 1000}
                                        onChange={handleOnChange}
                                        sx={{ width: '50%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>minutes</InputAdornment>
                                            )
                                        }}
                                    />
                                    <FormControlLabel
                                        label="Administrator ?"
                                        sx={{ mt: 2 }}
                                        control={
                                            <Checkbox
                                                name='administrator'
                                                id='administrator'
                                                checked={isAdmin}
                                                onChange={handleAdminChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item sx={{ display: isAdmin ? 'none' : 'flex', flexDirection: 'column', mx: 2, mb: 2, gap: 2 }} >
                                    <Typography sx={{ fontWeight: 500 }} color="primary">Add to the following alarm groups:</Typography>
                                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                        {alarmGroups.map((item, index) => (
                                            <FormControlLabel key={index}
                                                control={<Checkbox checked={selectedGroups.includes(item.id.toString())} />}
                                                value={item.id}
                                                label={item.name}
                                                sx={{ width: 250 }}
                                                onChange={handleGroupChange}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    );
}

export default UserProfile;