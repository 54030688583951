import axios from "axios";

const dataServiceUrl = `${process.env.REACT_APP_DATASERVICE_URL}`;
const interfaceServiceUrl = `${process.env.REACT_APP_INTERFACESERVICE_URL}`;

// const https = require("https");
// const agent = new https.Agent({
//     rejectUnauthorized: false,
//     requestCert: false,
//     agent: false
// });

// const apiClient = axios.create({
//     responseType: "json",
//     withCredentials: false,
//     httpsAgent: agent
// });


export function getHeader(token) {
    return {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
    };
};

export function handleErrors(error, onError) {
    if (Object.keys(error).length > 0 && error.response.status === 401) {
        window.location.replace("/signout");
    }
    else {
        onError(error);
    }
};

export function getMessages(token, page, rows, sort, desc, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/messages?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getTopics(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/message/topics", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getFilteredMessages(token, filter, page, rows, sort, desc, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/filtered-messages?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, filter, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getToken(settings, onSuccess, onFail) {
    const headers = {
        "username": settings.userName,
        "password": settings.password,
        "Content-Type": "application/json"
    };

    axios.get(dataServiceUrl + "/api/v1/auth/login", {
        headers: headers
    }).then((response) => {
        if (response.data.error) {
            alert(response.data.errors[0]);
            //window.location.replace("/signout");
        }
        else {
            onSuccess(response);
        }
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function replayMessage(token, messageId, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/message/replay/" + messageId, "{}", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        onFail(error);
    });
};

export  function getIntegrations(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/integrations", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function postIntegrations(token, body, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/integrations", body, {
        headers: getHeader(token)
    }).then(() => {
        onSuccess()
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getIntegrationStatus(token, onSuccess, onFail) {
    axios.get(interfaceServiceUrl + "/api/v1/integrations/status", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};


export function startIntegration(token, id, onSuccess, onFail) {
    axios.post(interfaceServiceUrl + "/api/v1/integrations/start/" + id, {}, {
        headers: getHeader(token)
    }).then(() => {
        onSuccess();
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function stopIntegration(token, id, onSuccess, onFail) {
    axios.post(interfaceServiceUrl + "/api/v1/integrations/stop/" + id, {}, {
        headers: getHeader(token)
    }).then(() => {
        onSuccess()
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function deleteIntegration(token, row, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/integrations", {
        headers: getHeader(token),
        data: JSON.stringify(row),
    }).then(() => {
        onSuccess();
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getAppLog(token, refId, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/log/" + refId, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function getAppLogs(token, refs, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/log/alarm", refs, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function getSettingCategories(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/settings/categories", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function getSettings(token, category, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/settings/" + category, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function saveSettings(token, username, data, onSuccess, onFail) {
    let header = getHeader(token);
    header.username = username;

    axios.post(dataServiceUrl + "/api/v1/data/settings", data, {
        headers: header
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getUsers(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/users", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function saveUser(token, user, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/user", user, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function deleteUser(token, userId, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/users/" + userId, {
        headers: getHeader(token)
    }).then(() => {
        onSuccess();
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getMessageSources(token, page, rows, sort, desc, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/message-sources?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function saveMessageSource(token, source, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/message-sources", source, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function deleteMessageSource(token, sourceId, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/message-sources/" + sourceId, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getAlarmPriorities(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/alarm/priorities", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function saveAlarmPriority(token, priority, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/alarm/priorities", priority, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function deleteAlarmPriority(token, priority, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/alarm/priorities", {
        headers: getHeader(token),
        data: JSON.stringify(priority),
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getAlarmTypes(token, page, rows, sort, desc, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/alarm/types?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function saveAlarmTypes(token, types, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/alarm/types", types, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function deleteAlarmTypes(token, types, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/alarm/types", {
        headers: getHeader(token),
        data: JSON.stringify(types),
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function importAlarmFile(token, data, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/alarm-types/upload", data, {
        headers: {"Authorization": "Bearer " + token}
    }).then((response) => {
        onSuccess(response)
    }).catch((error) => {
        handleErrors(error, onFail)
    });
};

export function getSourceFields(token, sourceId, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/source-fields/" + sourceId, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getActiveAlarms(token, page, rows, sort, desc, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/active-alarms?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getFilteredAlarms(token, filter, page, rows, sort, desc, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/filtered-alarms?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, filter, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getAlarmTotals(token, filter, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/alarm-totals", filter, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getDictionary(token, filter, page, rows, sort, desc, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/dictionary?page=" + page + "&pageRows=" + rows + "&sortOn=" + sort + "&desc=" + desc, filter, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getDictionaryCategories(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/dictionary/categories", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function saveDictionaryItems(token, items, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/dictionary/items", items, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getAlarmGroups(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/data/alarm-groups", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function saveAlarmGroup(token, group, onSuccess, onFail) {
    axios.post(dataServiceUrl + "/api/v1/data/alarm-groups", group, {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function deleteAlarmGroup(token, group, onSuccess, onFail) {
    axios.delete(dataServiceUrl + "/api/v1/data/alarm-groups", {
        headers: getHeader(token),
        data: group
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getMessageDayTotal(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/stats/messages/day", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};

export function getTopicTotals(token, onSuccess, onFail) {
    axios.get(dataServiceUrl + "/api/v1/stats/topic/count", {
        headers: getHeader(token)
    }).then((response) => {
        onSuccess(response);
    }).catch((error) => {
        handleErrors(error, onFail);
    });
};