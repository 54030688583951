import { useEffect, useState, useContext } from 'react';

import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogTitle
} from '@mui/material';
import JsonFormatter from 'react-json-formatter';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import ErrorDialog from '../components/ErrorDialog';
import { getAppLog, getAppLogs } from '../libs/apiCalls';

function AppLog({ refIds }) {
    const { settings } = useContext(ApplicationContext);

    const [messageData, setMessageData] = useState([]);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const columns = [
        {
            field: 'referenceId', headerName: 'Ref', width: 90,
            renderCell: (props) => <p>{props.row.logId}</p>
        },
        {
            field: 'date', headerName: 'Date', width: 200,
            valueFormatter: (date) => new Date(date?.value).toLocaleString()
        },
        { field: 'source', headerName: 'Source', width: 180 },
        { field: 'message', headerName: 'Message', width: 300 },
        { field: 'normalizedMessage', headerName: 'Output', width: 500 }
    ];

    useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getAppLogs(settings.token, refIds,
                (rows) => {
                    setMessageData(JSON.parse(rows.data.result));
                },
                (error) => {
                    setErrorMessage(error.message);
                    setShowError(true);
                }
            );
        }
    }, [settings, refIds]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <CustomDataGrid
                rows={messageData}
                columns={columns}
                disableRowSelectionOnClick
                getRowId={(row) => row.logId}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                onCellClick={(data) => {
                    if (data.field === "message") {
                        setDialogContent(data.row.message);
                        setOpenInfoDialog(true);
                    }
                    else if (data.field === "normalizedMessage") {
                        setDialogContent(data.row.normalizedMessage);
                        setOpenInfoDialog(true);
                    }
                }}

            />

            <ErrorDialog open={showError} title={"Application Error"}
                content={errorMessage}
                onOkClick={() => setShowError(false)} />

            <Dialog
                open={openInfoDialog}
                maxWidth="lg"
                fullWidth={true}
                PaperProps={{
                    sx: {
                        height: '50vh'
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                        setOpenInfoDialog(false);
                    }
                }}
            >
                <DialogTitle>Message Detail</DialogTitle>
                <DialogContent>
                    <div className={"overflow-div"}>
                        <JsonFormatter json={dialogContent}
                            tabWith={4}
                            jsonStyle={
                                {
                                    propertyStyle: { color: 'green' },
                                    stringStyle: { color: 'blue' },
                                    numberStyle: { color: 'red' },
                                    nullStyle: { color: 'gray' }
                                }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInfoDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AppLog;