
import { useContext, useState, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiDrawer from '@mui/material/Drawer';

import { AdminMenuItems } from './menuItems';
import { UserMenuItems } from '../userMenuItems';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import ApplicationContext from '../contexts/application';
import CustomDialog from '../components/CustomDialog';
import { Snackbar, useMediaQuery } from '@mui/material';

function Layout() {
    const { settings, dialogMessage, showDialog, clearDialog, 
        isError, badgeCount, setBadgeCount,
        snackbar, snackbarMessage, setShowSnackbar } = useContext(ApplicationContext);

    const mediumScreen = useMediaQuery('(min-height: 900px)');

    const [open, setOpen] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [snackbarDuration, setSnackbarDuration] = useState(2000);
    const [userRoles, setUserRoles] = useState('');

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const drawerWidth = 240;
    const defaultTheme = createTheme({
        palette: {
            primary: {
                main: '#447296'
            }
        }
    });

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
        }),
    );

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; 
        }

        setShowSnackbar(false);
    };

    useEffect(() => {
        if (Object.keys(settings).length > 0) {
            const admin = settings.roles && settings.roles?.includes("admin")

            setIsAdmin(admin);

            if (!admin) {
                if (settings.roles.length > 0) {
                    let groups = settings.roles.map(r => r.charAt(0).toUpperCase() + r.slice(1));
                    let names = '(' + groups.join(', ') + ')';

                    // TODO: replace this hack
                    names = names.replace('Eh_fm', 'EH FM').replace('Cahill_fm', 'Cahill FM');                    

                    setUserRoles(names);
                }
                else {
                    setUserRoles('(No roles assigned)');
                }
            }
        }
    }, [settings]);

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="absolute" open={open} style={{ boxShadow: "none" }}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                TwinWorX ESB Console {userRoles}
                            </Typography>
                            <Typography sx={{mr: 2}}>Welcome, {settings.userName}</Typography>
                            <IconButton color="inherit" component={Link} to={"/user"}>
                                <Badge color="error" badgeContent={badgeCount}>
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <Container component="div" style={{ width: "100%", marginLeft: "-15px", marginTop: "10px", alignContent: 'center' }}>
                                <img src="logo.png" alt="twinworx logo" style={{ width: "30px", height: "30px", marginRight: 12 }} />
                                <img src="nlhs.svg" alt="nlhs logo" style={{ width: '110px', pl: 2, display: mediumScreen ? 'none' : 'display' }} />

                            </Container>
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        { isAdmin ? <AdminMenuItems menuOpen={open} /> : <UserMenuItems menuOpen={open} />}

                        <Container component="div" style={{ alignContent: 'center', margin: 0, top: 'auto', bottom: '20px', right: 'auto', position: 'fixed', width: '100%', display: mediumScreen ? 'block' : 'none' }}>
                            <img  className={open ? '': 'logo-vertical'} style={{ width: '180px' }} src="nlhs.svg" alt="nlhs logo"  />
                        </Container>                  
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="false" sx={{ mt: 2, mb: 2, minWidth: 600 }}>
                            <Outlet />
                        </Container>
                    </Box>

                    <CustomDialog open={showDialog} title="TwinWorX ESB"
                        content={dialogMessage} isError={isError}
                        onOkClick={clearDialog} />

                    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={snackbar}
                        onClose={handleSnackbarClose}
                        autoHideDuration={snackbarDuration}
                        message={snackbarMessage}
                    />
                </Box>
            </ThemeProvider>

        </>
    )
}

export default Layout;