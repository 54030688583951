
import { Box, Checkbox, Typography, TextField, FormHelperText, Stack, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

function MqttIn({ row, onChange }) {
    const [useExternal, setUseExternal] = useState(false);
    const [useSsl, setUseSsl] = useState(row.useSsl);

    const handleChange = (event) => {
        setUseExternal(!useExternal);
    };

    // prevent warnings on setting checked for "controlled" elements
    const setChecked = (check) => {
        return { value: check }
    };

    const handleClick = (event) => {
        if (!event.target.checked) {
            row.endPoint = "";
            row.port = "";
            row.userName = "";
            row.password = "";
        }
    };

    const handleSslClicked = (event) => {
        row.useSsl = event.target.checked;
        setUseSsl(row.useSsl);
    };

    useEffect(() => {
        if (row.endPoint != null && row.endPoint !== "") {
            setUseExternal(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>MQTT Parameters - IN</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1, mb: -1 }}>
                <FormControlLabel checked={useExternal} control={
                    <Checkbox onChange={handleChange}
                        checked={setChecked(useExternal).value}
                        onClick={handleClick} />
                } label="Use external broker" />

                <FormControlLabel control={
                    <Checkbox
                        checked={useSsl}
                        onClick={handleSslClicked} />
                }
                    label="Use SSL"
                />
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ width: 0.5, mr: 2 }}>
                    <TextField
                        required={useExternal}
                        disabled={!useExternal}
                        margin="dense"
                        id="endPoint"
                        name="endPoint"
                        label="End Point"
                        type="text"
                        defaultValue={row.endPoint}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="endpoint-helper-text"
                    />
                    <FormHelperText id="endpoint-helper-text">
                        External broker address.
                    </FormHelperText>
                </Stack>
                <Stack sx={{ width: 100, mr: 2 }}>
                    <TextField
                        required={useExternal}
                        disabled={!useExternal}
                        margin="dense"
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        defaultValue={row.port}
                        onChange={onChange}
                        variant="standard"
                    />
                </Stack>
                <Stack sx={{ width: 100, flexGrow: 1 }}>
                    <TextField
                        required
                        margin="dense"
                        id="source"
                        name="source"
                        label="Topic"
                        type="text"
                        defaultValue={row.source}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="source-helper-text"
                    />
                    <FormHelperText id="source-helper-text">
                        Subscribe to messages from this broker topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <TextField sx={{ width: 0.31, mr: 2 }}
                    required={useExternal}
                    disabled={!useExternal}
                    margin="dense"
                    id="userName"
                    name="userName"
                    label="User Name"
                    type="text"
                    defaultValue={row.userName}
                    onChange={onChange}
                    variant="standard" />
                <TextField sx={{ width: 0.31, mr: 2 }}
                    required={useExternal}
                    disabled={!useExternal}
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    defaultValue={row.password}
                    onChange={onChange}
                    variant="standard" />
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ width: 0.64 }}>
                    <TextField
                        required
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="ESB Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send messages to this ESB topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="response"
                        name="response"
                        label="Response"
                        type="text"
                        rows={4}
                        multiline
                        defaultValue={row.response}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="response-helper-text"
                    />
                    <FormHelperText id="response-helper-text">
                        (Optional) Send this response back to the broker.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField sx={{ width: 0.5 }}
                        margin="dense"
                        id="outTopic"
                        name="outTopic"
                        label="Response Topic"
                        type="text"
                        defaultValue={row.outTopic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="outTopic-helper-text"
                    />
                    <FormHelperText id="outTopic-helper-text">
                        (Optional) Log activity to this ESB topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>
    );
};

export default MqttIn;