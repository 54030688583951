import React from "react";

import { useMsal } from "@azure/msal-react";
import ApplicationContext from "./contexts/application";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";


function SignOut() {
    const { settings, saveSettings } = React.useContext(ApplicationContext);
    const { instance } = useMsal();

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && settings.token !== "") {
            if (settings.oauth && settings.oauth.includes("microsoft")) {
                instance.logoutRedirect({ postLogoutRedirectUri: "/" });
            }

            saveSettings({
                "token": "",
                "userName": "",
                "password": "",
                "pageTitle": "",
                "name": "",
                "roles": [],
                "oauth": ""
            });
        }
    });

    return (
        <>
            <div className={"login-container"} sx={{ opacity: 0.1 }}>
                <div className={"login-left"}>
                </div>
                <div className={"login-right"}>
                    <Box component="form" className="login-box">
                        <h2>Your session has ended or expired!</h2>
                        <Link to="/">Log in</Link> again
                    </Box>
                </div>
            </div>
        </>
    );
};

export default SignOut;