
import { openDB } from "idb";

const LOCAL_DB_VERSION = 1;
const LOCAL_DB_NAME = `${process.env.REACT_APP_LOCAL_DB_NAME}`;
const LOCAL_DB_STORE = `${process.env.REACT_APP_LOCAL_DB_STORE}`;

async function getLocalDataStoreAsync() {

    const db = await openDB(LOCAL_DB_NAME, LOCAL_DB_VERSION, {
        upgrade(db) {
            db.createObjectStore(LOCAL_DB_STORE);
        }
    });

    return db;
}

async function getDataKeyAsync(key) {
    const db = await getLocalDataStoreAsync();
    return db.get(LOCAL_DB_STORE, key);
}

async function setDataKeyAsync(key, val) {
    const db = await getLocalDataStoreAsync();
    return db.put(LOCAL_DB_STORE, val, key);
}

async function removeDataKeyAsync(key) {
    const db = await getLocalDataStoreAsync();
    return db.delete(LOCAL_DB_STORE, key);
}

export {
    getLocalDataStoreAsync,
    getDataKeyAsync,
    setDataKeyAsync,
    removeDataKeyAsync
}
