import React from 'react';
import ReactDOM from 'react-dom/client';

import "./styles/app.css";
import App from './App';
import { ContextProvider } from './contexts/application';

import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ContextProvider>
        <App />
      </ContextProvider>
    </LocalizationProvider>
  </MsalProvider>
);


