import React from "react";

import { useMsal } from "@azure/msal-react";
import ApplicationContext from "./contexts/application";
import { Link } from "react-router-dom";
import { Box, Container, useMediaQuery } from "@mui/material";


function SignOut() {
    const { settings, saveSettings } = React.useContext(ApplicationContext);
    const { instance } = useMsal();
    const smallScreen = useMediaQuery('(min-height: 800px)');

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && settings.token !== "") {
            if (settings.oauth && settings.oauth.includes("microsoft")) {
                instance.logoutRedirect({ postLogoutRedirectUri: "/" });
            }

            saveSettings({
                "token": "",
                "userName": "",
                "password": "",
                "pageTitle": "",
                "name": "",
                "roles": [],
                "oauth": ""
            });
        }
    });

    return (
        <>
            <div className={"login-container"} sx={{ opacity: 0.1 }}>
                <div className={"login-left"}>
                </div>
                <div className={"login-right"}>
                    <Box component="form" className="login-box">
                        <h2>Your session has ended or expired!</h2>
                        <Link to="/">Log in</Link> again
                    </Box>
                </div>
            </div>
            <Container component="div" style={{ alignContent: 'center', margin: 20, top: 'auto', bottom: '20px', right: 'auto', position: 'fixed', width: '100%', display: smallScreen ? 'block' : 'none' }}>
                <img style={{ width: '350px' }} src="nlhs-white.svg" alt="nlhs logo" />
            </Container>
        </>
    );
};

export default SignOut;