
import { Box, TextField, NativeSelect } from "@mui/material";

function CommonParams({ row, onChange, onProtocolChange, onDirectionChange }) {

    return (
        <>
            <Box sx={{ display: "flex" }} spacing={2}>
                <TextField sx={{ mr: 2, width: 200 }}
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    defaultValue={row.name}
                    onChange={onChange}
                    variant="standard"
                />
                <TextField sx={{ flexGrow: 1 }}
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    defaultValue={row.description}
                    onChange={onChange}
                    variant="standard"
                />
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <NativeSelect sx={{ mr: 2, mb: 0.5, width: 200 }}
                    required
                    label="protocol"
                    onChange={onProtocolChange}
                    defaultValue={row.protocol}
                    inputProps={{
                        name: 'protocol',
                        id: 'protocol',
                    }}
                >
                    <option value="">(Protocol)</option>
                    <option value="API">API</option>
                    <option value="HL7">HL7</option>
                    <option value="MQTT">MQTT</option>
                    <option value="TCP">TCP</option>
                    <option value="UDP">UDP</option>
                </NativeSelect>
                <NativeSelect sx={{ mr: 2, mb: 0.5, width: 200 }}
                    required
                    label="direction"
                    onChange={onDirectionChange}
                    defaultValue={row.direction}
                    inputProps={{
                        name: 'direction',
                        id: 'direction',
                    }}
                >
                    <option value="">(Direction)</option>
                    <option value="IN">Incoming</option>
                    <option value="OUT">Outgoing</option>
                </NativeSelect>

            </Box>
        </>
    );

};

export default CommonParams;