
import { Box, Typography, TextField, NativeSelect, FormHelperText, Stack, FormControl } from "@mui/material";

function ApiOut({ row, onChange }) {
    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>API Parameters - OUT</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <Stack sx={{ width: 0.5, mr: 2 }}>
                    <TextField
                        required
                        margin="dense"
                        id="endPoint"
                        name="endPoint"
                        label="End Point"
                        type="text"
                        defaultValue={row.endPoint}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="endpoint-helper-text"
                    />
                    <FormHelperText id="endpoint-helper-text">
                        Full REST Endpoint URL (eg. http://emagic.ca:8080/api/v1/login).
                    </FormHelperText>

                </Stack>
                <FormControl sx={{ width: 110, mr: 2, mt: 3 }}>
                    <NativeSelect
                        required
                        variant="standard"
                        label="operation"
                        defaultValue={row.operation}
                        onChange={onChange}
                        inputProps={{
                            name: 'operation',
                            id: 'operation',
                        }}
                    >
                        <option value="">(Operation)</option>
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="DELETE">DELETE</option>
                    </NativeSelect>
                </FormControl>

                <Stack sx={{ minWidth: 150, flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Trigger Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send messages triggered by this topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="body"
                        name="body"
                        label="Message"
                        type="text"
                        rows={4}
                        multiline
                        defaultValue={row.body}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="body-helper-text"
                    />
                    <FormHelperText id="body-helper-text">
                        (Optional) For heartbeats: send this message to the endpoint.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Stack sx={{ width: 200, mr: 2 }}>
                    <TextField
                        margin="dense"
                        id="interval"
                        name="interval"
                        label="Interval (ms)"
                        type="number"
                        defaultValue={row.interval}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="interval-helper-text"
                    />
                    <FormHelperText id="interval-helper-text">
                        (Optional) For heartbeats.
                    </FormHelperText>
                </Stack>


                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="outTopic"
                        name="outTopic"
                        label="Output Topic"
                        type="text"
                        defaultValue={row.outTopic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="outTopic-helper-text"
                    />
                    <FormHelperText id="outTopic-helper-text">
                        (Optional) Log activity to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>

    );

};

export default ApiOut;