import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Login from './login';
import Layout from './admin/layout';
import Dashboard from './admin/dashboard';
import Alarms from './admin/alarms';
import Protected from './admin/protected';
import ErrorPage from './errors';
import SignOut from './signout';
import Messages from './messages';
import Integrations from './admin/integrations';
import AlarmPriorities from './admin/alarmPriorities';
import AlarmTypes from './admin/alarmTypes';
import AlarmViewer from './alarmViewer';
import Settings from './admin/settings';
import Users from './admin/users';
import UserProfile from './admin/userProfile';
import UserProtected from './protectedUser';
import MessageSources from './admin/sources';
import SourceDetails from './admin/sourceDetails';
import Lookups from './admin/lookups';
import AlarmGroups from './admin/alarmGroups';

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Login />,
  },
  {
    id: "user",
    path: "/user",
    element: (
      <UserProtected>
        <Layout />
      </UserProtected>
    ),
    children: [
      {
        index: true,
        element: <AlarmViewer />
      },
      // {
      //   path: "messages",
      //   element: <Messages />
      // }
    ]
  },
  {
    id: "signout",
    path: "/signout",
    element: <SignOut />    
  },
  {
    id: "admin",
    path: "/admin",
    element: (
      <Protected>
        <Layout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "integrations",
        element: <Integrations />
      },
      {
        path: "sources",
        element: <MessageSources />
      },
      {
        path: "source-details",
        element: <SourceDetails />
      },
      {
        path: "alarms",
        element: <Alarms />
      },
      {
        path: "alarmTypes",
        element: <AlarmTypes />
      },
      {
        path: "alarmPriorities",
        element: <AlarmPriorities />
      },
      {
        path: "alarmGroups",
        element: <AlarmGroups />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "users",
        element: <Users />     
      },
      {
        path: "user-profile",
        element: <UserProfile />
      },
      {
        path: "lookups",
        element: <Lookups />
      },
      {
        path: "messages",
        element: <Messages />
      }      
    ]
  },
  {
    id: "errors",
    path: "*",
    element: <ErrorPage />
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
