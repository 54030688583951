import React from 'react';

import {
    Box, Button, Stack, Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    Typography,
    Divider,
    MenuItem,
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    IconButton,
    LinearProgress
} from '@mui/material';

import dayjs from 'dayjs';
import JsonFormatter from 'react-json-formatter';

import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { DatePicker } from '@mui/x-date-pickers';
import { CustomDataGrid } from './components/CustomDataGrid';

import ApplicationContext from './contexts/application';
import ErrorDialog from './components/ErrorDialog';
import ConfirmDialog from './components/ConfirmDialog';
import AppLog from './admin/applog';

import { getFilteredMessages, getTopics, replayMessage } from './libs/apiCalls';

function Messages({ records }) {
    let defaultPageSize = !records ? 20 : records;

    const { settings } = React.useContext(ApplicationContext);
    const [messageId, setMessageId] = React.useState(0);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [gridFilter, setGridFilter] = React.useState([]);
    const [dialogContent, setDialogContent] = React.useState('');
    const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
    const [showReplayConfirm, setShowReplayConfirm] = React.useState(false);
    const [openLogDialog, setOpenLogDialog] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const [topics, setTopics] = React.useState([]);
    const [selectedTopic, setSelectedTopic] = React.useState('');
    const [gridRows, setGridRows] = React.useState([]);
    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const filter = React.useRef({
        searchString: '',
        fromDate: '',
        toDate: '',
        priority: '',
        topic: '',
        status: ''
    });

    const columns = [
        {
            field: 'referenceId', headerName: 'Ref', width: 90,
            renderCell: (props) => <>{props.row.messageId}</>
        },
        {
            field: 'messageDate', headerName: 'Date', width: 200,
            valueFormatter: (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss') 
        },
        { field: 'source', headerName: 'Source', width: 280 },
        { field: 'topic', headerName: 'Topic', width: 280 },
        { field: 'rawData', headerName: 'Raw Data', midWidth: 100, flex: 1 },
        {
            field: 'status',
            headerName: '',
            width: 80,
            renderCell: (props) => {
                const setDisplay = () => {
                    if (!settings.roles?.includes('admin') || !props.row.topic.includes('-in')) {
                        return { display: 'none' }
                    }

                    return {};
                };

                return (
                    <Stack sx={{ width: 100, flexGrow: 1, ...setDisplay() }}>
                        <ReplayCircleFilledIcon />
                    </Stack>
                );
            }
        }
    ];

    const handleReplayConfirm = () => {
        replayMessage(settings.token, messageId, () => { },
            (error) => {
                setErrorMessage(error.message);
                setShowError(true);
            }
        );

        setShowReplayConfirm(false);
    }

    const getData = (filter, page, pageSize) => {
        setLoading(true);
        getFilteredMessages(settings.token, JSON.stringify(filter), page, pageSize, 'CreatedDate', 1,
            (response) => {
                setServerData({
                    records: JSON.parse(response.data.result),
                    currentPage: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    totalRecords: response.data.totalRecords
                });

                setGridRows(JSON.parse(response.data.result));
                setLoading(false);
            },
            (error) => {
                setErrorMessage(error.message);
                setShowError(true);
                setLoading(false);
            }
        );
    };

    const getMessageTopics = () => {
        setLoading(true);

        getTopics(settings.token,
            (response) => {
                setTopics(JSON.parse(response.data.result));
                setLoading(false);
            },
            (error) => {
                setErrorMessage(error.message);
                setShowError(true);
                setLoading(false);
            }
        );
    };

    const handlePageModelChanged = (event) => {
        getData(filter.current, event.page, event.pageSize);
    };

    const topicItems = (topics) => {
        var items = [];
        items.push(
            <MenuItem key={-1} value=''><em>All</em></MenuItem>
        );

        topics.forEach((item, index) => {
            items.push(
                <MenuItem key={index} value={item}>{item}</MenuItem>
            );
        });

        return items;
    };

    const handleFilterChange = (event) => {
        filter.current[event.target.name] = event.target.value;

        if (event.target.name === 'topic') {
            setSelectedTopic(event.target.value);
        }
        else if (event.target.name === 'searchString') {
            setSearchString(event.target.value);
        }
    }

    const handleFilterDateChange = (target, date) => {
        filter.current[target] = date ? dayjs(date.$d).format('YYYY-MM-DD') : '';
    };

    const handleFilter = () => {
        getData(filter.current, 0, defaultPageSize);
    };

    const handleClearFilter = () => {
        filter.current.searchString = '';
        filter.current.fromDate = '';
        filter.current.toDate = '';
        filter.current.priority = '';
        filter.current.topic = '';
        filter.current.status = '';

        setSelectedTopic('');
        setSearchString('');
        getData(filter.current, 0, defaultPageSize);
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && gridRows.length === 0) {
            if (topics.length === 0) {
                getMessageTopics();
            }
        }
    }, [settings]);

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData(filter.current, 0, defaultPageSize);
        }
    }, [topics]);

    return (
        <>
            <Box sx={{ display: !records ? 'flex' : 'none', mb: 2, height: '60px' }}>
                <Box sx={{ width: '50%' }}>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '400' }} style={{ fontSize: '25px' }}>Messages</Typography>
                    <Typography sx={{ marginLeft: '5px', fontWeight: '300' }} style={{ fontSize: '15px' }}>All messages sent and received into the system.</Typography>
                </Box>
                <Box sx={{ display: !records ? 'flex' : 'none', justifyContent: 'flex-end', width: '50%', mb: 8 }}>
                </Box>
            </Box>
            <Divider sx={{ mb: 4, display: !records ? 'block' : 'none' }} />
            <Grid container gap={2} sx={{ width: '100%' }}>
                <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                    <TextField
                        variant='outlined'
                        name='searchString'
                        size='small'
                        placeholder='Search for any attribute or value'
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                        }}
                        value={searchString}
                        onChange={handleFilterChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleFilter();
                            }
                        }}
                        sx={{ width: '40%' }} />

                    <DatePicker
                        name='fromDate'
                        slotProps={{
                            textField: {
                                size: 'small',
                                placeholder: 'From Date'
                            }
                        }}
                        sx={{
                            width: '15%'
                        }}
                        onChange={(date) => handleFilterDateChange('fromDate', date)}
                        value={filter.current.fromDate ? dayjs(filter.current.fromDate) : null}
                    />

                    <DatePicker
                        name='toDate'
                        slotProps={{
                            textField: {
                                size: 'small',
                                placeholder: 'To Date'
                            }
                        }}
                        sx={{
                            width: '15%'
                        }}
                        onChange={(date) => handleFilterDateChange('toDate', date)}
                        value={filter.current.toDate ? dayjs(filter.current.toDate) : null}
                    />

                    <TextField
                        name='topic'
                        size='small'
                        select
                        sx={{
                            width: '25%',
                            '& .MuiSelect-select span::before': {
                                content: "'Topic'",
                            },
                        }}
                        value={selectedTopic}
                        onChange={handleFilterChange}
                    >
                        {topicItems(topics)}
                    </TextField>

                    <Tooltip title="Apply filter">
                        <IconButton
                            onClick={handleFilter}
                            color='primary'
                            sx={{ mt: -1, ml: -1 }}
                        >
                            <FilterAltIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear filters">
                        <IconButton
                            onClick={handleClearFilter}
                            color='primary'
                            sx={{ mt: -1, ml: -1 }}
                        >
                            <FilterAltOffIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <LinearProgress sx={{ width: '100%', display: loading ? 'block' : 'none' }} />
                    <CustomDataGrid
                        columns={columns}
                        paginationMode='server'
                        rows={gridRows}
                        rowCount={serverData.totalRecords}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                        }}

                        pagination
                        pageSizeOptions={[5, 10, 20, 50]}
                        onPaginationModelChange={handlePageModelChanged}

                        disableRowSelectionOnClick
                        getRowId={(row) => row.messageId}
                        onCellClick={(data) => {
                            if (data.field === 'topic') {
                                if (gridFilter.length === 0) {
                                    setGridFilter([
                                        {
                                            field: 'referenceId',
                                            operator: 'equals',
                                            value: data.row.referenceId
                                        }
                                    ])
                                }
                                else {
                                    setGridFilter([])
                                }
                            }
                            else if (data.field === 'rawData') {
                                setDialogContent(data.row.rawData);
                                setOpenInfoDialog(true);
                            }
                            else if (data.field === 'status') {
                                if (settings.roles?.includes('admin')) {
                                    setMessageId(data.row.messageId);
                                    setShowReplayConfirm(true);
                                }
                            }
                            else if (data.field === 'referenceId') {
                                setMessageId(data.row.referenceId);
                                setOpenLogDialog(true);
                            }
                        }}
                        filterModel={{ items: gridFilter }}
                        onFilterModelChange={(filter) => setGridFilter(filter)}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }

                        autoHeight
                    />

                    <ErrorDialog open={showError} title={'Application Error'}
                        content={errorMessage}
                        onOkClick={() => setShowError(false)} />

                    <Dialog
                        open={openInfoDialog}
                        maxWidth='lg'
                        fullWidth={true}
                        PaperProps={{
                            sx: {
                                height: '50vh'
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                setOpenInfoDialog(false);
                            }
                        }}
                    >
                        <DialogTitle>Message Detail</DialogTitle>
                        <DialogContent>
                            <div className={'overflow-div'}>
                                <JsonFormatter json={dialogContent}
                                    tabWith={4}
                                    jsonStyle={
                                        {
                                            propertyStyle: { color: 'green' },
                                            stringStyle: { color: 'blue' },
                                            numberStyle: { color: 'red' },
                                            nullStyle: { color: 'gray' }
                                        }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenInfoDialog(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <ConfirmDialog open={showReplayConfirm} title={'Confirm Replay'}
                        content={'Are you sure you want to re-run this message through the system?'}
                        onCancelClick={() => setShowReplayConfirm(false)}
                        onYesClick={handleReplayConfirm}
                        yesNo
                    />

                    <Dialog
                        open={openLogDialog}
                        maxWidth='lg'
                        fullWidth={true}
                        PaperProps={{
                            sx: {
                                height: '50vh',
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                setOpenLogDialog(false);
                            }
                        }}
                    >
                        <DialogTitle>Message Log</DialogTitle>
                        <DialogContent>
                            <AppLog refIds={[messageId]} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenLogDialog(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </>

    );
}

export default Messages;