
import { Box, Typography, TextField, FormHelperText, Stack } from "@mui/material";

function HL7In({ row, onChange }) {
    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>HL7 Parameters - IN</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <Stack sx={{ width: 200, mr: 2 }}>
                    <TextField
                        required
                        margin="dense"
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        defaultValue={row.port}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="port-helper-text"
                    />
                    <FormHelperText id="port-helper-text">
                        Receive messages on this port.
                    </FormHelperText>
                </Stack>
                <Stack sx={{ minWidth: 150, flexGrow: 1 }}>
                    <TextField
                        required
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send incoming messages to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>
    );
};

export default HL7In;