import React from 'react';
import { Button, Box, Typography, Divider, Container } from '@mui/material';

import ApplicationContext from '../contexts/application';
import { CustomDataGrid } from '../components/CustomDataGrid';
import { deleteMessageSource, getMessageSources } from '../libs/apiCalls';
import { useNavigate } from 'react-router';
import ConfirmDialog from '../components/ConfirmDialog';

function MessageSources() {
    const defaultPageSize = 20;
    const navigate = useNavigate();

    const { settings, setDialog, setSnackbar } = React.useContext(ApplicationContext);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [gridRows, setGridRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [reload, setReload] = React.useState(false);
    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const columns = [
        { field: 'sourceId', headerName: 'Ref', flex: 0.1 },
        { field: 'name', headerName: 'Name', flex: 0.1 },
        { field: 'description', headerName: 'Description', flex: 0.2 },
        { field: 'format', headerName: 'Format', flex: 0.1 },
        { field: 'topicIn', headerName: 'Source', flex: 0.2 },
        { field: 'topicOut', headerName: 'Destination', flex: 0.3 },
        { field: 'rawMessage', headerName: 'API Data', flex: 0.5 },
    ];

    const handleDelete = () => {
        if (Object.keys(selectedRow).length > 0) {
            setConfirmDelete(true);
        }
    };

    const handleEdit = () => {
        if (Object.keys(selectedRow).length > 0) {
            navigate('/admin/source-details', { state: selectedRow });
        }
    };

    const handleNew = () => {
        navigate('/admin/source-details', {
            state: {
                name: '',
                description: '',
                format: '',
                delimiter: '',
                topicIn: '',
                topicOut: '',
                rawMessage: '',
                createdBy: settings.userName
            }
        }
        );
    };

    const handlePageModelChanged = () => {

    };

    const handleCellDoubleClick = (params, event) => {
        navigate('/admin/source-details', { state: selectedRow });
    };

    const handleRowSelected = (ids) => {
        setSelectedRow(gridRows.find((row) => row.sourceId === ids[0]));
    };

    const doDelete = (event) => {
        deleteMessageSource(settings.token, selectedRow.sourceId,
            (response) => {
                if (!response.data.error) {
                    setSnackbar('Message source deleted successfully.');
                    setReload(!reload);
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        setConfirmDelete(false);
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getMessageSources(settings.token, 0, defaultPageSize, 'Name', 0,
                (response) => {
                    if (!response.data.error) {
                        setServerData({
                            records: JSON.parse(response.data.result),
                            currentPage: response.data.currentPage,
                            totalPages: response.data.totalPages,
                            totalRecords: response.data.totalRecords
                        });
                        setGridRows(JSON.parse(response.data.result));
                    }
                    else {
                        setDialog(response.data.errors[0], true);
                    }
                },
                (error) => {
                    setDialog(error.message, true);
                }
            )
        }
    }, [settings, reload])


    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Message Sources</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Configure incoming formats and destinations.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                    <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleDelete}>Delete</Button>
                    <Button variant="outlined" style={{ width: "120px", marginLeft: "7px" }} onClick={handleEdit}>Edit</Button>
                    <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} onClick={handleNew}>Add</Button>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth='xl'>
                <Box sx={{ width: '100%' }}>
                    <CustomDataGrid
                        columns={columns}
                        paginationMode='server'

                        rows={gridRows}
                        rowCount={serverData.totalRecords}
                        getRowId={(row) => row.sourceId}
                        onCellDoubleClick={handleCellDoubleClick}

                        pagination
                        pageSizeOptions={[10, 20]}
                        onPaginationModelChange={handlePageModelChanged}
                        onRowSelectionModelChange={handleRowSelected}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    />
                </Box>
            </Container>

            <ConfirmDialog open={confirmDelete} title={"Confirm Delete"}
                content={"Are you sure you want to remove this source? (Note: this cannot be undone)"}
                onCancelClick={() => setConfirmDelete(false)}
                onYesClick={doDelete}
                yesNo
            />
        </>

    )
}

export default MessageSources;