import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function Summary() {
  return (
    <React.Fragment>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>Summary</Typography>

      <Typography component="p" variant="h5">
        2,575
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        Messages
      </Typography>
      <Typography component="p" variant="h5">
        12
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        Integrations
      </Typography>
      <div>
        <Link color="primary" href="/admin/integrations">
          View Integrations
        </Link>
      </div>
    </React.Fragment>
  );
}