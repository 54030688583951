
import { Box, Typography, TextField, NativeSelect, FormHelperText, Stack } from "@mui/material";

function ApiIn({ row, onChange }) {
    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>API Parameters - IN</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <Stack sx={{ width: 0.5, mr: 2 }}>
                    <TextField
                        required
                        margin="dense"
                        id="endPoint"
                        name="endPoint"
                        label="End Point"
                        type="text"
                        defaultValue={row.endPoint}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="endpoint-helper-text"
                    />
                    <FormHelperText id="endpoint-helper-text">
                        Create this path on the server (eg. /api/v1/login).
                    </FormHelperText>
                </Stack>
                <NativeSelect sx={{ width: 110, mr: 2, mt: 3, mb: 4 }}
                    required
                    variant="standard"
                    label="operation"
                    defaultValue={row.operation}
                    onChange={onChange}
                    inputProps={{
                        name: 'operation',
                        id: 'operation',
                    }}
                >
                    <option value="">(Operation)</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                </NativeSelect>

                <Stack sx={{ minWidth: 150, flexGrow: 1 }}>
                    <TextField
                        required
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send incoming requests to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="response"
                        name="response"
                        label="Response"
                        type="text"
                        rows={4}
                        multiline
                        defaultValue={row.response}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="response-helper-text"
                    />
                    <FormHelperText id="response-helper-text">
                        (Optional) Send this response back to the caller.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField sx={{ width: 0.5 }}
                        margin="dense"
                        id="outTopic"
                        name="outTopic"
                        label="Response Topic"
                        type="text"
                        defaultValue={row.outTopic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="outTopic-helper-text"
                    />
                    <FormHelperText id="outTopic-helper-text">
                        (Optional) Log activity to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>
    );
};

export default ApiIn;