
import { Box, Typography, TextField, FormHelperText, Stack } from "@mui/material";

function TcpOut({ row, onChange }) {
    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>TCP/UDP Parameters - OUT</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <Stack sx={{ width: 0.5, mr: 2 }}>
                    <TextField
                        required
                        margin="dense"
                        id="endPoint"
                        name="endPoint"
                        label="Server"
                        type="text"
                        defaultValue={row.endPoint}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="endpoint-helper-text"
                    />
                    <FormHelperText id="endpoint-helper-text">
                        Server address, including port (eg. e-magic.ca:8080).
                    </FormHelperText>
                </Stack>
                <Stack sx={{ minWidth: 150, flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Trigger Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send messages triggered by this topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="body"
                        name="body"
                        label="Message"
                        type="text"
                        rows={4}
                        multiline
                        defaultValue={row.body}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="body-helper-text"
                    />
                    <FormHelperText id="body-helper-text">
                        (Optional) For heartbeats: send this message to the server.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Stack sx={{ width: 200, mr: 2 }}>
                    <TextField
                        margin="dense"
                        id="interval"
                        name="interval"
                        label="Interval (ms)"
                        type="number"
                        defaultValue={row.interval}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="interval-helper-text"
                    />
                    <FormHelperText id="interval-helper-text">
                        (Optional) For heartbeats.
                    </FormHelperText>
                </Stack>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="outTopic"
                        name="outTopic"
                        label="Message Topic"
                        type="text"
                        defaultValue={row.outTopic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="outTopic-helper-text"
                    />
                    <FormHelperText id="outTopic-helper-text">
                        (Optional) Log activity to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>
    );
};

export default TcpOut;