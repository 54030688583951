// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/login-background-mhac.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.login-container {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
}

.login-left {
    flex: 2 1;
    background-size: cover;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.login-right {
    flex: 1 0;
    display: flex;
    justify-content: center;
    text-align: center;
}

.login-box {
    justify-content: center;
    margin: 5px;
}

.login-box h2 {
    font-weight: bolder;
}

.login-box div {
    margin-bottom: 5px;
}

.overflow-div {
    inline-size: 100%;
    overflow-wrap: break-word;
    height: 350px;
    width: 100%;
}

.alarm-viewer {
    width: calc(100vw - 300px);
    height: calc(100vh - 100px);
}

.logo-vertical {
    transform: rotate(-90deg);
    margin-left: -82px;
    margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;IACX;AACJ;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,SAAW;IACX,sBAAsB;IACtB,yDAA4D;AAChE;;AAEA;IACI,SAAW;IACX,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["\r\nhtml, body {\r\n    margin: 0;\r\n    padding: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif\r\n}\r\n\r\n.login-container {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    height: 100vh;\r\n}\r\n\r\n.login-left {\r\n    flex: 2 1 0;\r\n    background-size: cover;\r\n    background-image: url(\"../images/login-background-mhac.jpg\");\r\n}\r\n\r\n.login-right {\r\n    flex: 1 0 0;\r\n    display: flex;\r\n    justify-content: center;\r\n    text-align: center;\r\n}\r\n\r\n.login-box {\r\n    justify-content: center;\r\n    margin: 5px;\r\n}\r\n\r\n.login-box h2 {\r\n    font-weight: bolder;\r\n}\r\n\r\n.login-box div {\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.overflow-div {\r\n    inline-size: 100%;\r\n    overflow-wrap: break-word;\r\n    height: 350px;\r\n    width: 100%;\r\n}\r\n\r\n.alarm-viewer {\r\n    width: calc(100vw - 300px);\r\n    height: calc(100vh - 100px);\r\n}\r\n\r\n.logo-vertical {\r\n    transform: rotate(-90deg);\r\n    margin-left: -82px;\r\n    margin-bottom: 60px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
