
import { Box, Typography, TextField, FormHelperText, Stack } from "@mui/material";

function TcpIn({ row, onChange }) {
    return (
        <>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Typography sx={{ fontWeight: "bold" }}>TCP/UDP Parameters - IN</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: 1 }} spacing={2}>
                <Stack sx={{ width: 300, mr: 2 }}>
                    <TextField
                        margin="dense"
                        id="endPoint"
                        name="endPoint"
                        label="End Point"
                        type="text"
                        defaultValue={row.endPoint}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="endpoint-helper-text"
                    />
                    <FormHelperText id="endpoint-helper-text">
                        (Optional). In client mode, listen on this server.
                    </FormHelperText>
                </Stack>                
                <Stack sx={{ width: 200, mr: 2 }}>
                    <TextField
                        required
                        margin="dense"
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        defaultValue={row.port}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="port-helper-text"
                    />
                    <FormHelperText id="port-helper-text">
                        Listen on this port on the server.
                    </FormHelperText>
                </Stack>
                <Stack sx={{ minWidth: 150, flexGrow: 1 }}>
                    <TextField
                        required
                        margin="dense"
                        id="topic"
                        name="topic"
                        label="Topic"
                        type="text"
                        defaultValue={row.topic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="topic-helper-text"
                    />
                    <FormHelperText id="topic-helper-text">
                        Send incoming messages to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex" }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField
                        margin="dense"
                        id="response"
                        name="response"
                        label="Response"
                        type="text"
                        rows={4}
                        multiline
                        defaultValue={row.response}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="response-helper-text"
                    />
                    <FormHelperText id="response-helper-text">
                        (Optional) Send this response back to the sender.
                    </FormHelperText>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Stack sx={{ flexGrow: 1 }}>
                    <TextField sx={{ width: 0.5 }}
                        margin="dense"
                        id="outTopic"
                        name="outTopic"
                        label="Response Topic"
                        type="text"
                        defaultValue={row.outTopic}
                        onChange={onChange}
                        variant="standard"
                        aria-describedby="outTopic-helper-text"
                    />
                    <FormHelperText id="outTopic-helper-text">
                        (Optional) Log activity to this topic.
                    </FormHelperText>
                </Stack>
            </Box>
        </>
    );
};

export default TcpIn;