import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from 'prop-types';

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onYesClick: PropTypes.func.isRequired,
    yesNo: PropTypes.bool
}

function ConfirmDialog(props) {

    return (
        <Dialog
            open={props.open}
            maxWidth="xs"
            fullWidth={true}
            keepMounted
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                {props.content} 
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancelClick}>{props.yesNo ? "No" : "Cancel"}</Button>
                <Button onClick={props.onYesClick} variant="contained">{props.yesNo ? "Yes" : "OK"}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmDialog;