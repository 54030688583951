import React from "react";

import {
    Box, Button, Container,
    Divider, Grid, IconButton,
    InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ApplicationContext from "../contexts/application";

import { CustomDataGrid } from "../components/CustomDataGrid";
import { getDictionary, getDictionaryCategories, saveDictionaryItems } from "../libs/apiCalls";
import { GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";

function Lookups() {
    let defaultPageSize = 20;

    const { settings, setDialog } = React.useContext(ApplicationContext);
    const [searchString, setSearchString] = React.useState('');
    const [categoryList, setCategoryList] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [isDirty, setIsDirty] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);

    const [gridRows, setGridRows] = React.useState([]);
    const columns = [
        { field: 'id', headerName: 'Id', width: 90 },
        { field: 'source', headerName: 'Source', flex: 0.33, editable: true },
        { field: 'value', headerName: 'Value', flex: 0.33, editable: true },
        {
            field: 'name',
            headerName: 'Category',
            flex: 0.33,
            renderCell: (data) => { return data.row?.dataCategory?.name },

            editable: true,
            type: 'singleSelect',
            valueOptions: categoryList.map((category) => { return { value: category.id, label: category.name } })
        }
    ];

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const filter = React.useRef({
        searchString: '',
        fromDate: '',
        toDate: '',
        priority: '',
        topic: '',
        status: ''
    });

    const getData = (filter, page, pageSize) => {
        getDictionaryCategories(settings.token,
            (response) => {
                if (!response.data.error) {
                    setCategoryList(JSON.parse(response.data.result));

                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );

        getDictionary(settings.token, JSON.stringify(filter), page, pageSize, 'CategoryId', 0,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });

                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    }

    const saveData = (row) => {
        row.updatedBy = row.id > 0 ? settings.userName : '';
        row.createdBy = row.id === 0 ? settings.userName : '';

        saveDictionaryItems(settings.token, JSON.stringify([row]),
            (response) => {
                if (!response.data.error) {
                    getData(filter.current, 0, defaultPageSize);
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const handlePageModelChanged = (event) => {

    };

    const handleFilter = () => {
        getData(filter.current, 0, defaultPageSize);
    };

    const handleFilterChange = (event) => {
        filter.current[event.target.name] = event.target.value;

        if (event.target.name === 'topic') {
            setSelectedCategory(event.target.value);
        }
        else if (event.target.name === 'searchString') {
            setSearchString(event.target.value);
        }
    };

    const handleClearFilter = () => {
        filter.current.searchString = '';
        filter.current.fromDate = '';
        filter.current.toDate = '';
        filter.current.priority = '';
        filter.current.topic = '';
        filter.current.status = '';

        setSelectedCategory('');
        setSearchString('');
        getData(filter.current, 0, defaultPageSize);
    };

    const handleCancel = () => {
        const newModel = { ...rowModesModel };
        Object.keys(newModel).forEach((item) => newModel[item] = { mode: GridRowModes.View, ignoreModifications: true });

        setRowModesModel(newModel);
        if (isDirty) {
            setIsDirty(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();


        setIsDirty(false);
    }

    const handleAddClick = (event) => {
        setAnchor(event.currentTarget);
    }

    const handleMenuClose = (event) => {
        setAnchor(null);
    };

    const handleNew = () => {
    };

    const handleImportFile = () => {
    };

    const handleRowModesModelChange = (newModel) => {
        setRowModesModel(newModel);

        const changed = Object.values(newModel).filter(item => item.mode === GridRowModes.Edit);
        setIsDirty(changed.length > 0);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleProcessRowUpdate = (newRow) => {
        const updated = { ...newRow };

        saveData(newRow);

        return updated;
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && gridRows.length === 0) {
            getData(filter.current, 0, defaultPageSize);
        }
    }, [settings]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ width: "50%" }}>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '400', fontSize: '25px' }}>Data Lookups</Typography>
                        <Typography sx={{ marginLeft: '5px', fontWeight: '300', fontSize: '15px' }}>Define data translation between source and system values.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                        {isDirty ?
                            <>
                                <Button variant="outlined" color="error" style={{ width: "120px" }} onClick={handleCancel}>Cancel</Button>
                                <Button variant="contained" color="success" style={{ width: "120px", marginLeft: "7px" }} type='submit'>Save</Button>
                            </> :
                            <>
                                <Button variant="contained" color="success"
                                    style={{ width: "120px", marginLeft: "7px" }}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    onClick={handleAddClick}
                                    disableElevation
                                    aria-haspopup="true"
                                >
                                    Add
                                </Button>
                                <Menu
                                    open={open}
                                    onClose={handleMenuClose}
                                    elevation={0}
                                    anchorEl={anchor}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    slotProps={{
                                        paper: {
                                            sx: {
                                                minWidth: 150,
                                                border: 1,
                                                borderColor: 'grey'
                                            },
                                        }
                                    }}
                                >
                                    <MenuItem onClick={handleNew} disableRipple sx={{ justifyContent: 'flex-end' }}>
                                        Add new item
                                    </MenuItem>
                                    <MenuItem onClick={handleImportFile} disableRipple sx={{ justifyContent: 'flex-end' }}>
                                        Import from file
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </Box>
                </Box>
                <Divider sx={{ mb: 4 }} />
                <Container maxWidth="xl">
                    <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                        <TextField
                            variant='outlined'
                            name='searchString'
                            size='small'
                            placeholder='Search for any attribute or value'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                            }}
                            value={searchString}
                            onChange={handleFilterChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleFilter();
                                }
                            }}
                            sx={{ width: '75%' }} />

                        <TextField
                            name='topic'
                            size='small'
                            select
                            sx={{
                                width: '25%',
                                '& .MuiSelect-select span::before': {
                                    content: "'Category'",
                                },
                            }}
                            value={selectedCategory}
                            onChange={handleFilterChange}
                        >
                            <MenuItem key={-1} value=''><em>All</em></MenuItem>
                            {categoryList.map((item, index) => (
                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            ))};
                        </TextField>

                        <Tooltip title="Apply filter">
                            <IconButton
                                onClick={handleFilter}
                                color='primary'
                                sx={{ mt: -1, ml: -1 }}
                            >
                                <FilterAltIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear filters">
                            <IconButton
                                onClick={handleClearFilter}
                                color='primary'
                                sx={{ mt: -1, ml: -1 }}
                            >
                                <FilterAltOffIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ display: 'flex', width: '100%', mt: 2 }} >
                        <CustomDataGrid
                            columns={columns}
                            paginationMode='server'
                            rows={gridRows}
                            rowCount={serverData.totalRecords}

                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: defaultPageSize,
                                    },
                                },
                            }}

                            pagination
                            pageSizeOptions={[5, 10, 20, 50]}
                            onPaginationModelChange={handlePageModelChanged}

                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}

                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }

                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}

                            processRowUpdate={handleProcessRowUpdate}
                            onProcessRowUpdateError={(error) => console.log(error)}

                            sx={{ minHeight: '50vh' }}
                        />
                    </Grid>
                </Container>
            </form>
        </>
    );
}

export default Lookups;