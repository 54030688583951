import React from 'react';

import { useTheme } from '@mui/material/styles';
import { BarChart, LineChart, axisClasses } from '@mui/x-charts';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';

import ApplicationContext from '../../contexts/application';
import { getMessageDayTotal } from '../../libs/apiCalls';

export default function Chart() {
    const theme = useTheme();

    const { settings, setDialog } = React.useContext(ApplicationContext);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const getData = () => {
        setLoading(true);

        getMessageDayTotal(settings.token,
            (response) => {
                if (!response.data.error) {
                    setData(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        )
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0) {
            getData();
        }
    }, [settings]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Message Volume (Today)</Typography>
            <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
                { loading || data.length === 0 ?
                    <CircularProgress color='success' /> :
                    <BarChart
                        dataset={data}
                        margin={{
                            top: 16,
                            left: 70,
                            bottom: 30,
                        }}
                        xAxis={[
                            {
                                scaleType: 'band',
                                dataKey: 'Hour',
                                tickLabelStyle: theme.typography.body2,
                            },
                        ]}
                        yAxis={[
                            {
                                label: '',
                                labelStyle: {
                                    ...theme.typography.body1,
                                    fill: theme.palette.text.primary,
                                },
                                tickLabelStyle: theme.typography.body2,
                                tickNumber: 3,
                            },
                        ]}
                        series={[
                            {
                                dataKey: 'Total',
                                showMark: false,
                                color: theme.palette.primary.light,
                            },
                        ]}
                        sx={{
                            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
                            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
                            [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                transform: 'translateX(-25px)',
                            },

                            display: loading ? 'none' : 'block'
                        }}
                    />
                }
            </div>
        </React.Fragment>
    );
}