import React from 'react';

import {
    Box, Button, Card, CardActionArea, CardContent,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    MenuItem,
    TextField, Tooltip, Typography
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ForwardIcon from '@mui/icons-material/Forward';

import ApplicationContext from './contexts/application';
import { CustomDataGrid } from './components/CustomDataGrid';
import { getAlarmTotals, getFilteredAlarms } from './libs/apiCalls';
import dayjs from 'dayjs';
import AppLog from './admin/applog';

function AlarmViewer() {
    const defaultPageSize = 20;
    const lastWeek = new Date().getDate() - 7;

    const { settings, setDialog, setBadgeCount, onMessage } = React.useContext(ApplicationContext);
    const [gridRows, setGridRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [priorityFilter, setPriorityFilter] = React.useState('');
    const [selectedPriority, setSelectedPriority] = React.useState('');
    const [searchString, setSearchString] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [openLogDialog, setOpenLogDialog] = React.useState(false);

    const [columVisibility, setColumnVisibility] = React.useState({
        inactiveDate: false
    });

    const alarmTotals = React.useRef({
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 0,
        ACtive: 0,
        Total: 0
    });

    const filter = React.useRef({
        searchString: '',
        fromDate: dayjs(new Date().setDate(lastWeek)).format('YYYY-MM-DD'),
        toDate: '',
        priority: '',
        topic: '',
        status: 'Active',
        roles: settings.roles
    });

    const [serverData, setServerData] = React.useState({
        records: [],
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0
    });

    const defaultColVisibility = {
        inactiveDate: false
    }

    const columns = [
        {
            field: 'id', headerName: 'ID', width: 80,
        },
        {
            field: 'activeDate', headerName: 'Active Date', width: 200,
            valueFormatter: (date) => new Date(date?.value).toLocaleString()
        },
        {
            field: 'inactiveDate', headerName: 'Inactive Date', width: 200,
            valueFormatter: (date) => date.value != null ? new Date(date.value).toLocaleString() : ''
        },
        { field: 'priority', headerName: 'Priority', width: 150 },
        { field: 'location', headerName: 'Location', flex: 0.3 },
        { field: 'type', headerName: 'Alarm Type', flex: 0.3 },
        { field: 'message', headerName: 'Alarm Message', flex: 1 },
        { field: 'source', headerName: 'Source', width: 125 },
        { field: 'status', headerName: 'Status', width: 100 },
    ];

    const getTotals = (filter) => {
        getAlarmTotals(settings.token, JSON.stringify(filter),
            (response) => {
                if (!response.data.error) {
                    const totals = JSON.parse(response.data.result);
                    totals.forEach((value, key) => {
                        alarmTotals.current[value.Name] = value.Count;
                    });

                    const active = totals.find(item => item.Name === 'Active');
                    if (active) {
                        setBadgeCount(active['Count']);
                    }
                }
                else {
                    setDialog(response.data.errors[0], true);
                }
            },
            (error) => {
                setDialog(error.message, true);
            }
        );
    };

    const getFilteredData = (filter, page, pageSize) => {
        setLoading(true);
        getTotals(filter);

        getFilteredAlarms(settings.token, JSON.stringify(filter), page, pageSize, 'activeDate', 1,
            (response) => {
                if (!response.data.error) {
                    setServerData({
                        records: JSON.parse(response.data.result),
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        totalRecords: response.data.totalRecords
                    });

                    setGridRows(JSON.parse(response.data.result));
                }
                else {
                    setDialog(response.data.errors[0], true);
                }

                setLoading(false);
            },
            (error) => {
                setDialog(error.message, true);
                setLoading(false);
            }
        );
    };

    const handlePageModelChanged = (event) => {
        getFilteredData(filter.current, event.page, event.pageSize);
    };

    const handleFilterChange = (event) => {
        filter.current[event.target.name] = event.target.value;

        if (event.target.name === 'searchString') {
            setSearchString(event.target.value);
        }

        setPriorityFilter(filter.current.priority);
    }

    const handleFilterDateChange = (target, date) => {
        filter.current[target] = date ? dayjs(date.$d).format('YYYY-MM-DD') : '';
        handleFilter();
    };

    const handleClearFilter = () => {
        filter.current.searchString = '';
        filter.current.fromDate = dayjs(new Date().setDate(lastWeek)).format('YYYY-MM-DD');
        filter.current.toDate = '';
        filter.current.priority = '';
        filter.current.status = 'Active';

        setPriorityFilter('');
        setSelectedPriority('');
        setSearchString('');
        setColumnVisibility(defaultColVisibility);

        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handlePriorityClick = (priority) => {
        if (selectedPriority === priority) {
            setSelectedPriority('');
            setColumnVisibility(defaultColVisibility);

            filter.current.status = 'Active';
            filter.current.priority = '';
        }
        else {
            setSelectedPriority(priority);

            filter.current.status = priority === 'Total' ? '' : 'Active';
            filter.current.priority = priority === 'Total' ? '' : priority;

            if (priority === 'Total') {
                setColumnVisibility({});
            }
        }

        setPriorityFilter('');
        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handleOpacity = (id) => {
        return selectedPriority === '' || selectedPriority === id ? 1 : 0.2;
    };

    const handleFilter = () => {
        getFilteredData(filter.current, 0, defaultPageSize);
    };

    const handleRowSelected = (ids) => {
        if (ids.length > 0) {
            setSelectedRow(gridRows.find((row) => row.id === ids[0]));
        }
    };    
    
    const handleCellDoubleClick = (params, event) => {
        setOpenLogDialog(true);
    };

    React.useEffect(() => {
        if (Object.keys(settings).length > 0 && gridRows.length === 0) {
            getFilteredData(filter.current, 0, defaultPageSize);
        }
    }, [settings]);

    React.useEffect(() => {
        if (Object.keys(onMessage).length > 0) {
            getFilteredData(filter.current, 0, defaultPageSize);
        }
    }, [onMessage]);

    return (
        <>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ width: "50%" }}>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '400' }} style={{ fontSize: "25px" }}>Alarm Viewer</Typography>
                    <Typography sx={{ marginLeft: "5px", fontWeight: '300' }} style={{ fontSize: "15px" }}>Review active and historical alarms. By default, for the last 7 days.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "50%", mb: 4 }}>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Container maxWidth='lg' sx={{ display: 'flex', gap: 2 }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#cf2d2e', opacity: () => handleOpacity('Critical') }} >
                        <Tooltip title='Show active critical alarms' placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Critical')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Critical</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Critical.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#ff9a03', opacity: () => handleOpacity('High') }} >
                        <Tooltip title='Show active high alarms' placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('High')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>High</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.High.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#1aa1fb', opacity: () => handleOpacity('Medium') }} >
                        <Tooltip title='Show active medium alarms' placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Medium')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Medium</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Medium.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: '#bc93f0', opacity: () => handleOpacity('Low') }} >
                        <Tooltip title='Show active low priority alarms' placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Low')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '50%' }}>Low</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Low.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
                <Grid item sx={{ flexGrow: 0.5 }}>
                    <Card variant="outlined" sx={{ height: 100, backgroundColor: 'darkgray', opacity: () => handleOpacity('Total') }}>
                        <Tooltip title='Show all alarms (inc. inactive)' placement='top'>
                            <CardActionArea onClick={(e) => handlePriorityClick('Total')}>
                                <CardContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 400, color: 'white', width: '80%' }}>All (Active/Inactive)</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '20%' }} >
                                            <NotificationsIcon sx={{ color: 'white' }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', fontSize: 30, mt: 1 }}>{alarmTotals.current.Total.toLocaleString()}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
            </Container>

            <Grid container gap={2} sx={{ mt: 2 }}>
                <Grid item gap={2} sx={{ display: 'flex', width: '100%' }}>
                    <TextField
                        variant='outlined'
                        name='searchString'
                        size='small'
                        placeholder='Search for any attribute or value'
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                        }}
                        value={searchString}
                        onChange={handleFilterChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleFilter();
                            }
                        }}
                        sx={{ width: '40%' }} />

                    <DatePicker
                        name='fromDate'
                        slotProps={{
                            textField: {
                                size: 'small',
                                placeholder: 'From Date'
                            }
                        }}
                        sx={{
                            width: '15%'
                        }}
                        onChange={(date) => handleFilterDateChange('fromDate', date)}
                        value={filter.current.fromDate ? dayjs(filter.current.fromDate) : null}
                    />

                    <DatePicker
                        name='toDate'
                        slotProps={{
                            textField: {
                                size: 'small',
                                placeholder: 'To Date'
                            }
                        }}
                        sx={{
                            width: '15%'
                        }}
                        onChange={(date) => handleFilterDateChange('toDate', date)}
                        value={filter.current.toDate ? dayjs(filter.current.toDate) : null}
                    />

                    <TextField
                        name='priority'
                        size='small'
                        select
                        sx={{
                            width: '20%',
                            '& .MuiSelect-select span::before': {
                                content: "'Priority'",
                            },
                        }}
                        onChange={handleFilterChange}
                        value={priorityFilter}
                    >
                        <MenuItem value=''><em>All</em></MenuItem>
                        <MenuItem value='Critical'>Critical</MenuItem>
                        <MenuItem value='High'>High</MenuItem>
                        <MenuItem value='Medium'>Medium</MenuItem>
                        <MenuItem value='Low'>Low</MenuItem>
                    </TextField>

                    <Tooltip title="Apply filter">
                        <IconButton
                            onClick={handleFilter}
                            color='primary'
                            sx={{ mt: -1, ml: -1 }}
                        >
                            <ForwardIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear filters">
                        <IconButton
                            onClick={handleClearFilter}
                            color='primary'
                            sx={{ mt: -1, ml: -1 }}
                        >
                            <RestorePageIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <LinearProgress sx={{ width: '100%', display: loading ? 'block' : 'none' }} />
                <Grid item sx={{ display: 'flex', width: '100%' }}>
                    <CustomDataGrid
                        columns={columns}
                        paginationMode='server'

                        rows={gridRows}
                        rowCount={serverData.totalRecords}
                        getRowId={(row) => row.id}

                        pagination
                        pageSizeOptions={[10, 20, 30, 50]}
                        onPaginationModelChange={handlePageModelChanged}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: defaultPageSize,
                                },
                            },
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterExcludeHiddenColumns: true
                                }
                            }
                        }}

                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }

                        columnVisibilityModel={columVisibility}
                        onRowSelectionModelChange={handleRowSelected}
                        onCellDoubleClick={handleCellDoubleClick}

                        autoHeight
                    />

                    <Dialog
                        open={openLogDialog}
                        maxWidth='lg'
                        fullWidth={true}
                        PaperProps={{
                            sx: {
                                height: '50vh',
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                setOpenLogDialog(false);
                            }
                        }}
                    >
                        <DialogTitle>Message Log</DialogTitle>
                        <DialogContent>
                            <AppLog refIds={[selectedRow.activeRefId, selectedRow.inactiveRefId]} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenLogDialog(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </>
    );
}

export default AlarmViewer;