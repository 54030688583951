import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import InboxIcon from '@mui/icons-material/Inbox';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LogoutIcon from '@mui/icons-material/Logout';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import SourceIcon from '@mui/icons-material/Source';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupsIcon from '@mui/icons-material/Groups';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, List } from '@mui/material';
import { Link } from 'react-router-dom';


export function AdminMenuItems({ menuOpen }) {
  const [alarmOpen, setAlarmOpen] = React.useState(false);
  const handleAlarmClick = () => {
    setAlarmOpen(!alarmOpen);
  }

  return (
    <List component="nav" aria-labelledby="main-menu" subheader={
      <ListSubheader component="div" id="main-menu" sx={{ display: menuOpen ? 'block' : 'none' }}>Main Menu</ListSubheader>
    }>
      <ListItemButton component={Link} to={"/admin"}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton component={Link} to={"/admin/integrations"}>
        <ListItemIcon>
          <IntegrationInstructionsIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton>
      
      <ListItemButton component={Link} to={"/admin/sources"}>
        <ListItemIcon>
          <SourceIcon />
        </ListItemIcon>
        <ListItemText primary="Sources" />
      </ListItemButton>

      <ListItemButton onClick={handleAlarmClick}>
        <ListItemIcon>
          <DoorbellIcon />
        </ListItemIcon>
        <ListItemText primary="Alarm" />
        {alarmOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={alarmOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ ml: 2 }}>
          <ListItemButton component={Link} to={"/admin/alarmPriorities"}>
            <ListItemIcon>
              <ImportExportIcon />
            </ListItemIcon>
            <ListItemText primary="Priorities" />
          </ListItemButton>
  
          <ListItemButton component={Link} to={"/admin/alarmGroups"}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Groups" />
          </ListItemButton>

          <ListItemButton component={Link} to={"/admin/alarmTypes"}>
            <ListItemIcon>
              <TypeSpecimenIcon />
            </ListItemIcon>
            <ListItemText primary="Types" />
          </ListItemButton>

          <ListItemButton component={Link} to={"/user"}>
            <ListItemIcon>
              <NotificationImportantIcon />
            </ListItemIcon>
            <ListItemText primary="Viewer" />
          </ListItemButton>  
        </List>
      </Collapse>

      <ListItemButton component={Link} to={"/user/messages"}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
      </ListItemButton>

      <ListItemButton>
        <ListItemIcon>
          <MonitorHeartIcon />
        </ListItemIcon>
        <ListItemText primary="Services" />
      </ListItemButton>

      <ListItemButton component={Link} to={"/admin/users"}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>


      <ListItemButton component={Link} to={"/admin/lookups"}>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary="Data Lookups" />
      </ListItemButton>
      
      <ListItemButton component={Link} to={"/admin/settings"}>
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>

      <Divider sx={{ my: 1 }} />

      <ListSubheader component="div" inset></ListSubheader>

      <ListItemButton component={Link} to={"/signout"}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </List>

  );
}
